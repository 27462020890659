import React, { createContext, FunctionComponent, useContext } from "react"
import config from "../config"
import {
  User,
  UserContext,
  Challenge,
  Product,
  Subscription,
  Identity,
  Submission,
  Notification,
  FeaturedProject,
  UserDeletionRequest,
} from "../types"
import { Diagnostics } from "../types/diagnostics"
import makeRequest, { PagedResponse } from "../utils/make-request"
import { useAuth } from "./auth-service"

const UserServiceContext = createContext<UserContext>(
  null as any as UserContext
)

const apiUrl = config.apiUrl

export const UserProvider: FunctionComponent = ({ children }) => {
  const auth = useAuth()
  const authToken = auth.getToken()

  const assertToken = () => {
    if (!authToken) {
      throw new Error("Missing token")
    }

    return authToken
  }

  // const token = assertToken()

  // Billing
  const getProducts = async (): Promise<PagedResponse<Product>> => {
    const productsResponse = await makeRequest<PagedResponse<Product>>(
      `${apiUrl}/internal/v1/billing/products`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return productsResponse
  }

  const getProductById = async (id: number): Promise<Product> => {
    const productResponse = await makeRequest<Product>(
      `${apiUrl}/internal/v1/billing/products/${id}`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return productResponse
  }

  const getProductSubscriptions = async (
    id: number
  ): Promise<PagedResponse<Subscription>> => {
    const productSubscriptions = await makeRequest<PagedResponse<Subscription>>(
      `${apiUrl}/internal/v1/billing/products/${id}/subscriptions`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}}`,
        },
      }
    )
    return productSubscriptions
  }

  const createProduct = async (
    title: string,
    description: string,
    type: string,
    price: number,
    duration: string,
    externalId: string,
    active: boolean
  ): Promise<Product> => {
    const createProductRequest = await makeRequest<Product>(
      `${apiUrl}/internal/v1/billing/products`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          description: description,
          type: type,
          price: price,
          duration: duration,
          externalId: externalId,
          active: active,
        }),
      }
    )
    return createProductRequest
  }

  const getSubscriptions = async (): Promise<PagedResponse<Subscription>> => {
    const subscriptionsResponse = await makeRequest<
      PagedResponse<Subscription>
    >(`${apiUrl}/internal/v1/billing/subscriptions`, {
      headers: {
        authorization: `Bearer ${assertToken()}`,
      },
    })
    return subscriptionsResponse
  }

  const getSubscriptionsForUser = async (
    userId: number | string
  ): Promise<PagedResponse<Subscription>> => {
    const userSubscriptionsResponse = await makeRequest<
      PagedResponse<Subscription>
    >(`${apiUrl}/internal/v1/users/${userId}/subscriptions`, {
      headers: {
        authorization: `Bearer ${assertToken()}`,
      },
    })
    return userSubscriptionsResponse
  }

  // Challenges
  const getChallenges = async (): Promise<PagedResponse<Challenge>> => {
    const challengesResponse = await makeRequest<PagedResponse<Challenge>>(
      `${apiUrl}/internal/v1/challenges`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return challengesResponse
  }

  const getChallengeSubmissions = async (): Promise<
    PagedResponse<Submission>
  > => {
    const challengeSubmissionsResponse = await makeRequest<
      PagedResponse<Submission>
    >(`${apiUrl}/internal/v1/challenges/submissions`, {
      headers: {
        authorization: `Bearer ${assertToken()}`,
      },
    })
    return challengeSubmissionsResponse
  }

  const removeSubmission = async (id: number | string): Promise<Submission> => {
    const removeSubmissionResponse = await makeRequest<Promise<Submission>>(
      `${apiUrl}/internal/v1/challenges/submissions`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      }
    )
    return removeSubmissionResponse
  }

  const createChallenge = async (
    kicker: string,
    name: string,
    subtitle: string,
    description: string,
    image: string,
    active: boolean
  ): Promise<Challenge> => {
    const createChallengeResponse = await makeRequest<Challenge>(
      `${apiUrl}/internal/v1/challenges`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kicker: kicker,
          name: name,
          subtitle: subtitle,
          description: description,
          image: image,
          active: active,
        }),
      }
    )
    return createChallengeResponse
  }

  const editChallenge = async (
    id: number,
    kicker: string,
    name: string,
    subtitle: string,
    description: string,
    type: string,
    image: string,
    active: boolean
  ): Promise<Challenge> => {
    const editChallengeResponse = await makeRequest<Challenge>(
      `${apiUrl}/internal/v1/challenges`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          kicker: kicker,
          name: name,
          subtitle: subtitle,
          description: description,
          type: type,
          image: image,
          active: active,
        }),
      }
    )
    return editChallengeResponse
  }

  // Diagnostics
  const getDiagnostics = async (): Promise<Diagnostics> => {
    const diagnosticsResponse = await makeRequest<Diagnostics>(
      `${apiUrl}/internal/v1/diagnostics`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return diagnosticsResponse
  }

  // Explore
  const getFeatured = async (): Promise<PagedResponse<FeaturedProject>> => {
    const featuredResponse = await makeRequest<PagedResponse<FeaturedProject>>(
      `${apiUrl}/internal/v1/explore?limit=100`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return featuredResponse
  }

  const featureProject = async (
    projectId: number,
    challengeId: number | null,
    userId: number
  ): Promise<Submission> => {
    const featureProjectResponse = await makeRequest<Promise<Submission>>(
      `${apiUrl}/internal/v1/explore`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId: projectId,
          challengeId: challengeId,
          userId: userId,
        }),
      }
    )
    return featureProjectResponse
  }

  // Notifications
  const sendMassPush = async (
    title: string,
    message: string
  ): Promise<Notification> => {
    const massPushResponse = await makeRequest<Promise<Notification>>(
      `${apiUrl}/internal/v1/notifications/mass`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          message: message,
        }),
      }
    )
    return massPushResponse
  }

  const sendUserPush = async (
    title: string,
    message: string,
    userId: number
  ): Promise<Notification> => {
    const userPushResponse = await makeRequest<Promise<Notification>>(
      `${apiUrl}/internal/v1/notifications`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          message: message,
          userId: userId,
        }),
      }
    )
    return userPushResponse
  }

  const getRecentNotifications = async (): Promise<
    PagedResponse<Notification>
  > => {
    const notificationsResponse = await makeRequest<
      PagedResponse<Notification>
    >(`${apiUrl}/internal/v1/notifications`, {
      headers: {
        authorization: `Bearer ${assertToken()}`,
      },
    })
    return notificationsResponse
  }

  // Projects

  const replayFailedStability = async (): Promise<void> => {
    const regenResponse = await makeRequest<Promise<void>>(
      `${apiUrl}/internal/v1/projects/stability/replay-failed`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${assertToken()}`,
          "Content-Type": "application/json",
        },
      }
    )
    return regenResponse
  }

  // Users
  const getUserById = async (userId: number | string): Promise<User> => {
    const userResponse = await makeRequest<User>(
      `${apiUrl}/internal/v1/users/${userId}`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return userResponse
  }

  const getIdentitiesForUser = async (
    userId: number | string
  ): Promise<PagedResponse<Identity>> => {
    const identitiesResponse = await makeRequest<PagedResponse<Identity>>(
      `${apiUrl}/internal/v1/users/${userId}/identities`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return identitiesResponse
  }

  const getNotificationsForUser = async (
    userId: number | string
  ): Promise<PagedResponse<Notification>> => {
    const notificationsResponse = await makeRequest<
      PagedResponse<Notification>
    >(`${apiUrl}/internal/v1/users/${userId}/notifications`, {
      headers: {
        authorization: `Bearer ${assertToken()}`,
      },
    })
    return notificationsResponse
  }

  const searchForUsers = async (query: string): Promise<User[]> => {
    const userSearch = await makeRequest<User[]>(
      `${apiUrl}/internal/v1/users/search?query=${query}`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return userSearch
  }

  const getUserDeletionRequests = async (): Promise<
    PagedResponse<UserDeletionRequest>
  > => {
    const usersResponse = await makeRequest<PagedResponse<UserDeletionRequest>>(
      `${apiUrl}/internal/v1/users/deletion-requests`,
      {
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return usersResponse
  }

  const processUserDeletionRequest = async (
    requestId: number
  ): Promise<UserDeletionRequest> => {
    const userDeletionResponse = await makeRequest<UserDeletionRequest>(
      `${apiUrl}/internal/v1/users/deletion-requests/${requestId}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${assertToken()}`,
        },
      }
    )
    return userDeletionResponse
  }

  const value = {
    getProducts,
    getProductById,
    getProductSubscriptions,
    createProduct,
    getSubscriptions,
    getSubscriptionsForUser,
    getChallenges,
    getChallengeSubmissions,
    removeSubmission,
    createChallenge,
    editChallenge,
    getDiagnostics,
    getFeatured,
    featureProject,
    sendMassPush,
    sendUserPush,
    getRecentNotifications,
    replayFailedStability,
    getUserById,
    getIdentitiesForUser,
    getNotificationsForUser,
    searchForUsers,
    getUserDeletionRequests,
    processUserDeletionRequest,
  }

  return (
    <UserServiceContext.Provider value={value}>
      {children}
    </UserServiceContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserServiceContext)
}
