import React, { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Card, CardContent, Chip, Grid, Typography } from "@mui/material"
import useGetUserNotes from "./hooks/useGetUserNotes"
import useGetIPAddressDetails from "./hooks/useGetIPAddressDetails"
import useGetIPAddressUsers from "./hooks/useGetIPAddressUsers"
import UsersWithIPAddressTable from "./components/UsersWithIPAddressTable"

type Params = {
  ipAddress?: string
}

const IPAddressDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const ipAddress = params.ipAddress

  const { getIPAddressDetails, loading, error, details } =
    useGetIPAddressDetails()
  const { getIPAddressUsers, users } = useGetIPAddressUsers()

  useEffect(() => {
    getIPAddressDetails(String(ipAddress))
    getIPAddressUsers(String(ipAddress))
    // eslint-disable-next-line
  }, [])

  if (!ipAddress || !details) {
    return null
  }

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? <LoadingSpinner /> : null}
      <Card sx={{ minWidth: 275, margin: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4">{details.ip_address}</Typography>
              {details.security.is_vpn && (
                <Chip
                  label="VPN"
                  sx={{
                    backgroundColor: "lightblue",
                    color: "white",
                    borderRadius: 2,
                    marginRight: 0.5,
                  }}
                />
              )}
              <Typography variant="subtitle1">
                {details.flag.emoji} {details.city}, {details.region} (
                {details.country})
              </Typography>
              <Typography variant="subtitle1">
                {details.timezone.name} ({details.timezone.abbreviation})
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, margin: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Users with this IP Address
              </Typography>
              <UsersWithIPAddressTable users={users || []} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ScreenContainer>
  )
}

export default IPAddressDetailsScreen
