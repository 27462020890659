import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Challenge, CreateChallenge } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateChallenge = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const create = useCallback(
    (challenge: CreateChallenge): Promise<Challenge> => {
      return makeRequest<Challenge>(`${config.apiUrl}/internal/v1/challenges`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...challenge }),
      })
    },
    [authBearer]
  )

  const createChallenge = useCallback(
    async (challenge: CreateChallenge, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await create(challenge)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [create, setLoading, setError]
  )

  return { createChallenge, loading, error }
}

export default useCreateChallenge
