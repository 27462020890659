import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Challenge } from "../../../types"
import ChallengeRow from "./ChallengeRow"

interface ChallengesTableProps {
  challenges: Challenge[]
  paginationData: PaginationData
}

const getRows = (challenges: Challenge[]) => {
  return challenges.map((challenge, index) => {
    return <ChallengeRow key={index} challenge={challenge} />
  })
}

const columns: string[] = ["ID", "Prompt", "Date", "Active", "Action"]

const ChallengesTable = ({
  challenges,
  paginationData,
}: ChallengesTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(challenges)}
      paginationOptions={paginationData}
    />
  )
}

export default ChallengesTable
