import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { RewardRedemption } from "../../../types"
import { convertCentsToDollar, formatDate } from "../../../utils/formatters"

type Props = {
  rewardRedemption: RewardRedemption
}

const UserRewardRedemptionRow: FunctionComponent<Props> = ({
  rewardRedemption,
}) => {
  return (
    <BasicTableRow key={rewardRedemption.id}>
      <BasicTableCell component="th" scope="row">
        {rewardRedemption.brand_name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        ${convertCentsToDollar(rewardRedemption.amount)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(rewardRedemption.created_at)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserRewardRedemptionRow
