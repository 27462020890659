import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { PostReport } from "../../../types"
import ReportedPostRow from "./ReportedPostRow"

interface ReportedPostsTableProps {
  reports: PostReport[]
  paginationData: PaginationData
}

const getRows = (reports: PostReport[]) => {
  return reports.map((report, index) => {
    return <ReportedPostRow key={index} report={report} />
  })
}

const columns: string[] = [
  "Photo",
  "Post ID",
  "Caption",
  "Posted By",
  "Reported By User",
  "Requested",
  "Action",
]

const ReportedPostsTable = ({
  reports,
  paginationData,
}: ReportedPostsTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(reports)}
      paginationOptions={paginationData}
    />
  )
}

export default ReportedPostsTable
