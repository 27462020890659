import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AssetModeration } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetAssetModerationQueue = (defaultRowsPerPage = 25) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<AssetModeration>>()

  const [type, setType] = useState<string>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (type) {
      getAssetModerationQueue(type)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback(
    (type: string): Promise<PagedResponse<AssetModeration>> => {
      let queryParams = `type=${type}&limit=${rowsPerPage}&offset=${
        rowsPerPage * page
      }`
      return makeRequest<PagedResponse<AssetModeration>>(
        `${config.apiUrl}/internal/v1/assets/moderation?${queryParams}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getAssetModerationQueue = useCallback(
    async (type: string) => {
      setType(type)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(type)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getAssetModerationQueue,
    assets: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetAssetModerationQueue
