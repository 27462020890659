import React, { FunctionComponent } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import ChallengesScreen from "../../screens/Challenges/ChallengesScreen"
import LoginScreen from "../../screens/Login/LoginScreen"
import ReportedPostsScreen from "../../screens/Posts/ReportedPostsScreen"
import UserDeletionRequestsScreen from "../../screens/Users/UserDeletionRequestsScreen"
import UserDetailsScreen from "../../screens/Users/UserDetailsScreen"
import UserSearchScreen from "../../screens/Users/UserSearchScreen"
import BaseLayout from "../shared/BaseLayout"
import ProtectedRoute from "./ProtectedRoute"
import ReportedUsersScreen from "../../screens/Users/ReportedUsersScreen"
import LeaderboardScreen from "../../screens/Leaderboard/LeaderboardScreen"
import UserFollowersScreen from "../../screens/Users/UserFollowersScreen"
import ChallengeDetailsScreen from "../../screens/Challenges/ChallengeDetailsScreen"
import PostsScreen from "../../screens/Posts/PostsScreen"
import ProfilePhotoModerationScreen from "../../screens/Users/ProfilePhotoModerationScreen"
import ContestsScreen from "../../screens/Contests/ContestsScreen"
import UserPostsScreen from "../../screens/Users/UserPostsScreen"
import UserRewardsScreen from "../../screens/Users/UserRewardsScreen"
import HuntsScreen from "../../screens/Hunts/HuntsScreen"
import UserFollowingScreen from "../../screens/Users/UserFollowingScreen"
import PostModerationScreen from "../../screens/Posts/PostModerationScreen"
import UserSubscriptionsScreen from "../../screens/Users/UserSubscriptionsScreen"
import UserNotificationsScreen from "../../screens/Users/UserNotificationsScreen"
import IPAddressDetailsScreen from "../../screens/Users/IPAddressDetailsScreen"
import EventsScreen from "../../screens/Events/EventsScreen"
import DeadletterEventsScreen from "../../screens/Events/DeadletterEventsScreen"

const Router: FunctionComponent = () => (
  <Routes>
    <Route path="/" element={<BaseLayout />}>
      <Route path="/" element={<Navigate to="/users" replace />} />
      <Route
        path="/users"
        element={<ProtectedRoute children={<UserSearchScreen />} />}
      />
      <Route
        path="/deletion-requests"
        element={<ProtectedRoute children={<UserDeletionRequestsScreen />} />}
      />
      <Route
        path="/users/:userId"
        element={<ProtectedRoute children={<UserDetailsScreen />} />}
      />
      <Route
        path="/users/:userId/followers"
        element={<ProtectedRoute children={<UserFollowersScreen />} />}
      />
      <Route
        path="/users/:userId/following"
        element={<ProtectedRoute children={<UserFollowingScreen />} />}
      />
      <Route
        path="/users/:userId/posts"
        element={<ProtectedRoute children={<UserPostsScreen />} />}
      />
      <Route
        path="/users/:userId/rewards"
        element={<ProtectedRoute children={<UserRewardsScreen />} />}
      />
      <Route
        path="/users/:userId/subscriptions"
        element={<ProtectedRoute children={<UserSubscriptionsScreen />} />}
      />
      <Route
        path="/users/:userId/notifications"
        element={<ProtectedRoute children={<UserNotificationsScreen />} />}
      />
      <Route
        path="/challenges"
        element={<ProtectedRoute children={<ChallengesScreen />} />}
      />
      <Route
        path="/challenges/:challengeId"
        element={<ProtectedRoute children={<ChallengeDetailsScreen />} />}
      />
      <Route
        path="/posts"
        element={<ProtectedRoute children={<PostsScreen />} />}
      />
      <Route
        path="/post-reports"
        element={<ProtectedRoute children={<ReportedPostsScreen />} />}
      />
      <Route
        path="/user-reports"
        element={<ProtectedRoute children={<ReportedUsersScreen />} />}
      />
      <Route
        path="/leaderboard"
        element={<ProtectedRoute children={<LeaderboardScreen />} />}
      />
      <Route
        path="/profile-photo-moderation"
        element={<ProtectedRoute children={<ProfilePhotoModerationScreen />} />}
      />
      <Route
        path="/post-moderation"
        element={<ProtectedRoute children={<PostModerationScreen />} />}
      />
      <Route
        path="/contests"
        element={<ProtectedRoute children={<ContestsScreen />} />}
      />
      <Route
        path="/hunts"
        element={<ProtectedRoute children={<HuntsScreen />} />}
      />
      <Route
        path="/ip-addresses/:ipAddress"
        element={<ProtectedRoute children={<IPAddressDetailsScreen />} />}
      />
      <Route
        path="/events"
        element={<ProtectedRoute children={<EventsScreen />} />}
      />
      <Route
        path="/deadletter"
        element={<ProtectedRoute children={<DeadletterEventsScreen />} />}
      />
    </Route>
    <Route path="/login" element={<LoginScreen />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default Router
