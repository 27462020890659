import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Contest, CreateContest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateContest = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const create = useCallback(
    (contest: CreateContest): Promise<Contest> => {
      return makeRequest<Contest>(`${config.apiUrl}/internal/v1/contests`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...contest }),
      })
    },
    [authBearer]
  )

  const createContest = useCallback(
    async (contest: CreateContest, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await create(contest)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [create, setLoading, setError]
  )

  return { createContest, loading, error }
}

export default useCreateContest
