import React, { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title1, Title3 } from "../../components/shared/Typography"
import { Box } from "@mui/material"
import useGetUserFollowers from "./hooks/useGetUserFollowers"
import UserFriendsTable from "./components/UserFriendsTable"

type Params = {
  userId?: string
}

const UserFollowersScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const userId = Number(params.userId)

  const {
    getUserFollowers,
    followers,
    loading,
    error,
    paginationData: friendsPaginationData,
  } = useGetUserFollowers()

  useEffect(() => {
    getUserFollowers(userId)
    // eslint-disable-next-line
  }, [])

  if (!followers) {
    return <Title1>Followers not found for user {userId}</Title1>
  }

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
        }}
      ></Box>
      <Title3>User {userId}'s Followers:</Title3>
      {loading ? <LoadingSpinner /> : null}
      <UserFriendsTable
        friends={followers}
        paginationData={friendsPaginationData}
      />
    </ScreenContainer>
  )
}

export default UserFollowersScreen
