import { Box } from "@mui/material"
import React, { FunctionComponent, ReactNode } from "react"
import { LargeTitle, TextBody } from "./Typography"

interface ScreenProps {
  title?: string
  subtitle?: string
  children?: ReactNode
}

const ScreenContainer: FunctionComponent<ScreenProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Box sx={{ flexDirection: "column" }}>
      {!!title && (
        <Box sx={{ flexDirection: "row" }}>
          <LargeTitle>{title}</LargeTitle>
          <TextBody>{subtitle}</TextBody>
        </Box>
      )}

      <Box display="flex" sx={{ flexDirection: "column", flex: 1 }}>
        {children}
      </Box>
    </Box>
  )
}

export default ScreenContainer
