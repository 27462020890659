import { useCallback, useState } from "react"
import { useAuth } from "../services/auth-service"
import assertAuthToken from "../utils/assertAuthToken"
import enforceError from "../utils/enforce-error"

const useHandleRequest = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleSetError = useCallback((error: any) => {
    if (error) {
      setError(enforceError(error))
    }
  }, [])

  const auth = useAuth()
  const authToken = auth.getToken()

  return {
    loading,
    setLoading,
    error,
    setError: handleSetError,
    authBearer: assertAuthToken(authToken),
  }
}

export default useHandleRequest
