import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LeaderboardTable from "./components/LeaderboardTable"
import useGetLeaderboard from "./hooks/useGetLeaderboard"

const LeaderboardScreen: FunctionComponent = () => {
  const { leaderboard, paginationData, getLeaderboard, error, loading } =
    useGetLeaderboard()

  useEffect(() => {
    getLeaderboard(null, null, false, false)
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Leaderboard">
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <LeaderboardTable
          leaderboard={leaderboard}
          paginationData={paginationData}
        />
      )}
    </ScreenContainer>
  )
}

export default LeaderboardScreen
