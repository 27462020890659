import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { UserReport } from "../../../types"
import ReportedUserRow from "./ReportedUserRow"

interface ReportedUsersTableProps {
  reports: UserReport[]
  paginationData: PaginationData
}

const getRows = (reports: UserReport[]) => {
  return reports.map((report, index) => {
    return <ReportedUserRow key={index} report={report} />
  })
}

const columns: string[] = [
  "Profile Photo",
  "User ID",
  "Name",
  "Username",
  "Reported By User",
  "Reported",
  "Action",
]

const ReportedUsersTable = ({
  reports,
  paginationData,
}: ReportedUsersTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(reports)}
      paginationOptions={paginationData}
    />
  )
}

export default ReportedUsersTable
