import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { LeaderboardUser } from "../../../types"
import LeaderboardUserRow from "./LeaderboardUserRow"

interface LeaderboardTableProps {
  leaderboard: LeaderboardUser[]
  paginationData: PaginationData
}

const getRows = (leaderboard: LeaderboardUser[]) => {
  return leaderboard.map((user, index) => {
    return <LeaderboardUserRow key={index} user={user} />
  })
}

const columns: string[] = [
  "Rank",
  "Profile Photo",
  "Name",
  "Username",
  "Points",
]

const LeaderboardTable = ({
  leaderboard,
  paginationData,
}: LeaderboardTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(leaderboard)}
      paginationOptions={paginationData}
    />
  )
}

export default LeaderboardTable
