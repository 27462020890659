import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Hunt } from "../../../types"
import HuntRow from "./HuntRow"

interface Props {
  hunts: Hunt[]
  paginationData: PaginationData
}

const getRows = (hunts: Hunt[]) => {
  return hunts.map((hunt, index) => {
    return <HuntRow key={index} hunt={hunt} />
  })
}

const columns: string[] = [
  "ID",
  "Name",
  "Code",
  "Live",
  "Private",
  "Seed Game",
  "Created",
]

const HuntsTable = ({ hunts, paginationData }: Props) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(hunts)}
      paginationOptions={paginationData}
    />
  )
}

export default HuntsTable
