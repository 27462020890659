import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Notification } from "../../../types"
import UserNotificationRow from "./UserNotificationRow"

interface UserNotificationsTableProps {
  notifications: Notification[]
  paginationData: PaginationData
}

const getRows = (notifications: Notification[]) => {
  return notifications.map((item, index) => {
    return <UserNotificationRow key={index} notification={item} />
  })
}

const columns: string[] = [
  "Asset",
  "Type",
  "Title",
  "Message",
  "In App",
  "Push",
  "Read",
  "Created At",
]

const UserNotificationsTable = ({
  notifications,
  paginationData,
}: UserNotificationsTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(notifications)}
      paginationOptions={paginationData}
    />
  )
}

export default UserNotificationsTable
