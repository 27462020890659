import { Button, FormLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { Contest } from "../../../types"
import useCreateUserNotification from "../hooks/useCreateUserNotification"

interface Props {
  isOpen: boolean
  close: () => void
  userId: number
}

const UserNotificationDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  userId,
}) => {
  const { createUserNotification, error, loading } = useCreateUserNotification()

  const validationSchema = Yup.object().shape({
    type: Yup.string(),
    asset: Yup.string(),
    title: Yup.string(),
    message: Yup.string(),
    in_app: Yup.boolean(),
    push: Yup.boolean(),
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      type: "admin",
      asset: "",
      title: "",
      message: "",
      in_app: true,
      push: true,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await createUserNotification(
        userId,
        {
          type: values.type,
          asset: values.asset === "" ? null : values.asset,
          title: values.title === "" ? null : values.title,
          message: values.message === "" ? null : values.message,
          in_app: values.in_app,
          push: values.push,
        },
        handleCreateSuccess
      )
    },
  })

  const handleCreateSuccess = () => {
    toast.success("Successfully created notification")
    close()
  }

  return (
    <Dialog
      title="Create Notification"
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            autoFocus
            margin="dense"
            id="type"
            name="type"
            label="Type"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.type}
          />
          <TextField
            margin="dense"
            id="asset"
            name="asset"
            label="Asset"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.asset}
          />
          <TextField
            margin="dense"
            id="title"
            name="title"
            label="Title"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <TextField
            margin="dense"
            id="message"
            name="message"
            label="Message"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          <FormLabel>In-App</FormLabel>
          <Switch
            id="in_app"
            name="in_app"
            checked={formik.values.in_app}
            onChange={formik.handleChange}
            color="primary"
          />
          <FormLabel>Push Notification</FormLabel>
          <Switch
            id="push"
            name="push"
            checked={formik.values.push}
            onChange={formik.handleChange}
            color="primary"
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Create Notification
          </Button>
        </>
      }
    />
  )
}

export default UserNotificationDialog
