import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PostReport } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateReportedPost = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const update = useCallback(
    (reportId: number, action: string): Promise<PostReport> => {
      return makeRequest<PostReport>(
        `${config.apiUrl}/internal/v1/posts/reports/${reportId}?action=${action}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
        }
      )
    },
    [authBearer]
  )

  const updateReportedPost = useCallback(
    async (reportId: number, action: string, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await update(reportId, action)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [update, setLoading, setError]
  )

  return { updateReportedPost, loading, error }
}

export default useUpdateReportedPost
