import React, { FunctionComponent, useEffect } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { PostReport } from "../../../types"
import { formatDate } from "../../../utils/formatters"
import config from "../../../config"
import useGetPostById from "../hooks/useGetPostById"
import UserLink from "../../Users/components/UserLink"
import { Button, Menu, MenuItem } from "@mui/material"
import { toast } from "react-hot-toast"
import useUpdateReportedPost from "../hooks/useUpdateReportedPost"

type ReportedPostRowProps = {
  report: PostReport
}

const ReportedPostRow: FunctionComponent<ReportedPostRowProps> = ({
  report,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { post, getPostById } = useGetPostById()
  const { updateReportedPost } = useUpdateReportedPost()

  const handlePostActionsClicked = async (action: string) => {
    switch (action) {
      case "deletePost":
        toast.loading("Deleting post...")
        await updateReportedPost(report.id, action, handleDeletePostSuccess)
        break
      case "deleteReport":
        toast.loading("Removing report...")
        await updateReportedPost(report.id, action, handleDeleteReportSuccess)
        break
      default:
        toast.error("Invalid action clicked")
    }
  }

  const handleDeletePostSuccess = async () => {
    toast.dismiss()
    toast.success("Successfully deleted post & report")
    window.location.reload()
  }

  const handleDeleteReportSuccess = async () => {
    toast.dismiss()
    toast.success("Successfully deleted report")
    window.location.reload()
  }

  useEffect(() => {
    getPostById(report.type_id)
    // eslint-disable-next-line
  }, [])

  return (
    <BasicTableRow key={report.id}>
      <BasicTableCell component="th" scope="row">
        <img
          src={`${config.assetsBase}/${post?.photo}`}
          width="100"
          height="100"
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {report.type_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {post?.caption}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={post?.user_id!}>{post?.user_id}</UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={report.reported_by}>{report.reported_by}</UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(report.created_at)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handlePostActionsClicked("deletePost")}>
            Delete Post
          </MenuItem>
          <MenuItem onClick={() => handlePostActionsClicked("deleteReport")}>
            Delete Report (post remains)
          </MenuItem>
        </Menu>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default ReportedPostRow
