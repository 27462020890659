import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Challenge, CreateChallenge } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useCreateChallengeNextDate = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const getMostRecentChallenge = useCallback((): Promise<
    PagedResponse<Challenge>
  > => {
    let queryParams = `limit=1&offset=0`
    return makeRequest<PagedResponse<Challenge>>(
      `${config.apiUrl}/internal/v1/challenges?${queryParams}`,
      {
        headers: {
          authorization: `Bearer ${authBearer}`,
        },
      }
    )
  }, [authBearer])

  const create = useCallback(
    (challenge: CreateChallenge): Promise<Challenge> => {
      return makeRequest<Challenge>(`${config.apiUrl}/internal/v1/challenges`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...challenge }),
      })
    },
    [authBearer]
  )

  const createChallengeNextDate = useCallback(
    async (challenge: CreateChallenge, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        const mostRecentChallenge = await getMostRecentChallenge()
        var date = new Date(mostRecentChallenge.data[0].date)
        date.setDate(date.getDate() + 1)
        const month = date.getUTCMonth() + 1
        const day = date.getUTCDate()
        const year = date.getUTCFullYear()
        const fullDate = `${month}-${day}-${year}`
        result = await create({
          ...challenge,
          date: `${fullDate} 00:00:00+00`,
        })
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [create, setLoading, setError]
  )

  return { createChallengeNextDate, loading, error }
}

export default useCreateChallengeNextDate
