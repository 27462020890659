import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { UserDeletionRequest } from "../../../types"
import { formatDate } from "../../../utils/formatters"
import useProcessUserDeleteionRequestById from "../hooks/useProcessUserDeletionRequestById"
import { toast } from "react-hot-toast"
import { Button } from "@mui/material"

type UserDeletionRequestRowProps = {
  deletionRequest: UserDeletionRequest
}

const UserDeletionRequestRow: FunctionComponent<
  UserDeletionRequestRowProps
> = ({ deletionRequest }) => {
  const { processUserDeletionRequest, loading, error } =
    useProcessUserDeleteionRequestById()

  const handleProcessUserDeletionRequest = async () => {
    try {
      await processUserDeletionRequest(deletionRequest.id)
      toast.success("Successfully deleted user")
    } catch (err) {
      toast.error(`${error?.message}`)
    }
  }

  return (
    <BasicTableRow key={deletionRequest.id}>
      <BasicTableCell component="th" scope="row">
        {deletionRequest.user_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(deletionRequest.created_at)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button onClick={handleProcessUserDeletionRequest} disabled={loading}>
          Delete User
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserDeletionRequestRow
