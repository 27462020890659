import { Box, TablePagination } from "@mui/material"
import React, { FunctionComponent, useCallback, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SearchInput from "../../components/shared/SearchInput"
import UserListComponent from "./components/UserList"
import useSearchUsers from "./hooks/useSearchUsers"

const UserSearchScreen: FunctionComponent = () => {
  const navigate = useNavigate()
  const { searchUsers, loading, error, users, paginationData } =
    useSearchUsers()
  let [searchParams, setSearchParams] = useSearchParams()

  const handleSearchFromRouter = useCallback(async () => {
    const term = searchParams.get("term")

    if (term) {
      await searchUsers(term)
    }
  }, [searchParams, searchUsers])

  useEffect(() => {
    handleSearchFromRouter()
    // eslint-disable-next-line
  }, [])

  const handleSearchUsers = useCallback(
    async (searchValue: string) => {
      if (!isNaN(searchValue as any)) {
        navigate(`/users/${searchValue}`)
      } else {
        await searchUsers(searchValue)
        setSearchParams({ term: searchValue as string })
      }
    },
    [navigate, setSearchParams, searchUsers]
  )

  const showResults = !loading && users.length > 0
  return (
    <ScreenContainer title="Users Search">
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <SearchInput
          handleSearch={handleSearchUsers}
          title={"Search by name, username, or email"}
          label={"User Search"}
          disabledWhenEmpty
          defaultValue={searchParams.get("term")}
        />
      </Box>

      {loading && (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {showResults && (
        <>
          <TablePagination
            component="div"
            count={paginationData.count}
            page={paginationData.page}
            onPageChange={paginationData.onPageChange}
            rowsPerPage={paginationData.rowsPerPage}
            onRowsPerPageChange={paginationData.onRowsPerPageChange}
          />
          <UserListComponent users={users} />
        </>
      )}
      {error ? <ErrorComponent error={error} /> : null}
    </ScreenContainer>
  )
}

export default UserSearchScreen
