import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Contest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useSetContestWinners = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const create = useCallback(
    (contestId: number, userIds: number[]): Promise<Contest> => {
      return makeRequest<Contest>(
        `${config.apiUrl}/internal/v1/contests/${contestId}/winners`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_ids: userIds }),
        }
      )
    },
    [authBearer]
  )

  const setContestWinners = useCallback(
    async (contestId: number, userIds: number[], onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await create(contestId, userIds)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [create, setLoading, setError]
  )

  return { setContestWinners, loading, error }
}

export default useSetContestWinners
