import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useGenerateAIPrompts from "../hooks/useGenerateAIPrompts"
import AIChallengesTable from "./AIChallengesTable"

interface Props {
  isOpen: boolean
  close: () => void
}

const AIChallengeDialog: FunctionComponent<Props> = ({ isOpen, close }) => {
  const { generateAIPrompts, loading, error, data } = useGenerateAIPrompts()
  const [difficulty, setDifficulty] = React.useState("all")

  const validationSchema = Yup.object().shape({
    prompts: Yup.number().integer().required(),
    difficulty: Yup.string().required(),
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDifficulty((event.target as HTMLInputElement).value)
  }

  const handleGenerateSuccess: any = async () => {
    toast.success("Successfully generated AI prompts")
  }

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      prompts: 20,
      difficulty: "all",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      await generateAIPrompts(values.prompts, difficulty, handleGenerateSuccess)
    },
  })

  return (
    <Dialog
      title={"Challenge Generator"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {!data ? (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="prompts"
                name="prompts"
                label="Prompts to Generate"
                fullWidth
                variant="outlined"
                required
                type={"number"}
                inputMode={"numeric"}
                onChange={formik.handleChange}
                value={formik.values.prompts}
                error={!!formik.errors.prompts}
              />
              <FormControl>
                <FormLabel>Difficulty</FormLabel>
                <RadioGroup
                  defaultValue="all"
                  name="radio-buttons-group"
                  value={difficulty}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All Levels"
                  />
                  <FormControlLabel
                    value="easy"
                    control={<Radio />}
                    label="Easy"
                  />
                  <FormControlLabel
                    value="medium"
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value="hard"
                    control={<Radio />}
                    label="Hard"
                  />
                </RadioGroup>
              </FormControl>
            </>
          ) : (
            <AIChallengesTable prompts={data} />
          )}
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            {!data ? "Cancel" : "Close"}
          </Button>
          {!data ? (
            <Button
              onClick={formik.submitForm}
              color="primary"
              variant="outlined"
              disabled={loading}
            >
              Generate
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default AIChallengeDialog
