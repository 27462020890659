import React, { useEffect } from "react"
import { Menu as MenuIcon } from "@mui/icons-material"
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { capitalizeFirstLetter } from "../../utils/formatters"
import LogoutButton from "./LogoutButton"
import useBreakpoint from "../../hooks/useBreakpoint"
import useToggle from "../../hooks/useToggle"

interface LinkItem {
  path: string
  label?: string
  activeResolver?: () => boolean
}

const DRAWER_WIDTH = 175

const BaseLayout = () => {
  const {
    isOn: menuIsShowing,
    turnOn: showMenu,
    turnOff: hideMenu,
    toggle: toggleMenu,
  } = useToggle(true)
  const navigate = useNavigate()
  const location = useLocation()
  const breakpoint = useBreakpoint()

  useEffect(() => {
    if (
      !!menuIsShowing &&
      (breakpoint === "xxs" || breakpoint === "xs" || breakpoint === "sm")
    ) {
      hideMenu()
    } else if (
      !menuIsShowing &&
      breakpoint !== "xxs" &&
      breakpoint !== "xs" &&
      breakpoint !== "sm"
    ) {
      showMenu()
    }

    // eslint-disable-next-line
  }, [breakpoint])

  const userLinks: LinkItem[] = [
    { path: "users" },
    { path: "deletion-requests", label: "Deletion Requests" },
    { path: "profile-photo-moderation", label: "Profile Photo Mod" },
  ]

  const eventsLinks: LinkItem[] = [
    { path: "events" },
    { path: "deadletter", label: "Deadletter Events" },
  ]

  const huntsLinks: LinkItem[] = [{ path: "hunts" }]

  const postLinks: LinkItem[] = [
    { path: "posts" },
    { path: "post-moderation", label: "Post Mod" },
  ]

  const reportLinks: LinkItem[] = [
    { path: "post-reports", label: "Reported Posts" },
    { path: "user-reports", label: "Reported Users" },
  ]

  const featuredLinks: LinkItem[] = [
    { path: "challenges" },
    { path: "contests" },
    { path: "leaderboard" },
  ]

  const adminLinks: LinkItem[] = [
    { path: "diagnostics" },
    { path: "notifications" },
  ]

  const getLinks = (links: LinkItem[]) => {
    return (
      <List>
        {links.map((item, index) => {
          let isActive: boolean = false
          if (item?.activeResolver) {
            isActive = item.activeResolver()
          } else {
            isActive = location?.pathname.startsWith(`/${item.path}`)
          }

          const textProps: any = {
            primary: item.label || capitalizeFirstLetter(item.path),
          }
          if (isActive) {
            textProps.style = { textDecoration: "underline" }
          }
          return (
            <ListItem button key={index} onClick={() => navigate(item.path)}>
              <ListItemText {...textProps} />
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 100,
          backgroundColor: "black",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <IconButton onClick={toggleMenu} size="large" sx={{ mr: "12px" }}>
              <MenuIcon htmlColor="white" fontSize="large" />
            </IconButton>
            <img height={45} src={"/logo.svg"} alt={"Scavenge"} />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={menuIsShowing}
        onClose={toggleMenu}
        variant="persistent"
        hideBackdrop
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: menuIsShowing ? DRAWER_WIDTH : 0,
          flexShrink: 0,
          "& .MuiPaper-root": {
            width: menuIsShowing ? DRAWER_WIDTH : 0,
          },
        }}
      >
        <Toolbar />
        <Box overflow="auto">
          {getLinks(userLinks)}
          <Divider />
          {getLinks(eventsLinks)}
          <Divider />
          {getLinks(huntsLinks)}
          <Divider />
          {getLinks(postLinks)}
          <Divider />
          {getLinks(reportLinks)}
          <Divider />
          {getLinks(featuredLinks)}
          <Divider />
          <List>
            <ListItem key={0}>
              <LogoutButton />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          m: "15px",
          pt: "56px",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default BaseLayout
