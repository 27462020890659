import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Challenge } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetChallengeById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Challenge>()

  const request = useCallback(
    (challengeId: number): Promise<Challenge> => {
      return makeRequest<Challenge>(
        `${config.apiUrl}/internal/v1/challenges/${challengeId}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getChallengeById = useCallback(
    async (challengeId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(challengeId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getChallengeById,
    challenge: data,
  }
}

export default useGetChallengeById
