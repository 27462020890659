import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Subscription } from "../../../types"
import UserSubscriptionRow from "./UserSubscriptionRow"

interface UserSubscriptionsTableProps {
  subscriptions: Subscription[]
  paginationData: PaginationData
}

const getRows = (subscriptions: Subscription[]) => {
  return subscriptions.map((item, index) => {
    return <UserSubscriptionRow key={index} subscription={item} />
  })
}

const columns: string[] = ["Store", "SKU", "Price", "Status"]

const UserSubscriptionsTable = ({
  subscriptions,
  paginationData,
}: UserSubscriptionsTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(subscriptions)}
      paginationOptions={paginationData}
    />
  )
}

export default UserSubscriptionsTable
