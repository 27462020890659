/* eslint-disable no-template-curly-in-string */
interface AppConfig {
  environmentName: string
  googleClientId: string
  apiUrl: string
  assetsBase: string
}

const localConfig: AppConfig = {
  environmentName: "local",
  googleClientId:
    "725808757178-0rhifc37eajeqejh4pnh4hh81jb2gn1c.apps.googleusercontent.com",
  apiUrl: "https://api-staging.scavenge.app",
  assetsBase: "https://assets-staging.scavenge.app",
}

const remoteConfig: AppConfig = {
  environmentName: "${REACT_APP_ENVIRONMENT_NAME}",
  googleClientId: "${REACT_APP_GOOGLE_CLIENT_ID}",
  apiUrl: "${REACT_APP_API_URL}",
  assetsBase: "${REACT_APP_ASSETS_BASE}",
}

const config: AppConfig =
  process.env.REACT_APP_LOCAL === "true" ? localConfig : remoteConfig

export default config
