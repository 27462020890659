import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { UserDeletionRequest } from "../../../types"
import UserDeletionRequestRow from "./UserDeletionRequestRow"

interface UserDeletionRequestsTableProps {
  deletionRequests: UserDeletionRequest[]
  paginationData: PaginationData
}

const getRows = (deletionRequests: UserDeletionRequest[]) => {
  return deletionRequests.map((deletionRequest, index) => {
    return (
      <UserDeletionRequestRow key={index} deletionRequest={deletionRequest} />
    )
  })
}

const columns: string[] = ["User ID", "Requested", "Action"]

const UserDeletionRequestsTable = ({
  deletionRequests,
  paginationData,
}: UserDeletionRequestsTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(deletionRequests)}
      paginationOptions={paginationData}
    />
  )
}

export default UserDeletionRequestsTable
