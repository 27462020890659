import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Box } from "@mui/material"
import ProfilePhotoModerationTable from "./components/ProfilePhotoModerationTable"
import useGetAssetModerationQueue from "./hooks/useGetAssetModerationQueue"

const ProfilePhotoModerationScreen: FunctionComponent = () => {
  const {
    getAssetModerationQueue,
    assets,
    loading,
    error,
    paginationData: friendsPaginationData,
  } = useGetAssetModerationQueue()

  useEffect(() => {
    getAssetModerationQueue("profilePhoto")
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Profile Photo Moderation Queue">
      {error ? <ErrorComponent error={error} /> : null}
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
        }}
      ></Box>
      {loading ? <LoadingSpinner /> : null}
      <ProfilePhotoModerationTable
        assets={assets}
        paginationData={friendsPaginationData}
      />
    </ScreenContainer>
  )
}

export default ProfilePhotoModerationScreen
