import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreateNotification, UpdateUser } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateUserNotification = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const request = useCallback(
    (
      userId: number,
      createNotificationBody: CreateNotification
    ): Promise<UpdateUser> => {
      return makeRequest<UpdateUser>(
        `${config.apiUrl}/internal/v1/users/${userId}/notifications`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...createNotificationBody }),
        }
      )
    },
    [authBearer]
  )

  const createUserNotification = useCallback(
    async (
      userId: number,
      createNotificationBody: CreateNotification,
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await request(userId, createNotificationBody)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [request, setLoading, setError]
  )

  return { createUserNotification, loading, error }
}

export default useCreateUserNotification
