import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { User } from "../../../types"
import config from "../../../config"
import UserLink from "./UserLink"
import { VerifiedIcon } from "@primer/octicons-react"

type IPAddressUserRowProps = {
  user: User
}

const IPAddressUserRow: FunctionComponent<IPAddressUserRowProps> = ({
  user,
}) => {
  return (
    <BasicTableRow key={user.id}>
      <BasicTableCell component="th" scope="row">
        {user.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <img
          src={`${config.assetsBase}/${
            user.profile_photo ? user.profile_photo : "users/null.png"
          }`}
          width="50"
          height="50"
          style={{
            borderRadius: user.type === "personal" ? "50%" : "10%",
          }}
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={user.id}>
          {user.name}
          {user.verified ? (
            <>
              {" "}
              <VerifiedIcon />
            </>
          ) : null}
        </UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {user.username}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default IPAddressUserRow
