import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Follower } from "../../../types"
import Avatar from "../../../components/shared/Avatar"
import config from "../../../config"
import UserLink from "./UserLink"
import { VerifiedIcon } from "@primer/octicons-react"

type UserFriendRowProps = {
  friend: Follower
}

const UserFriendRow: FunctionComponent<UserFriendRowProps> = ({ friend }) => {
  // Following

  if (friend.following) {
    return (
      <BasicTableRow key={friend.id}>
        <BasicTableCell component="th" scope="row">
          <Avatar
            src={`${config.assetsBase}/${
              friend.following.profile_photo
                ? friend.following.profile_photo
                : "users/null.png"
            }`}
            style={{
              borderRadius:
                friend.following.type === "personal" ? "50%" : "10%",
            }}
          />
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <UserLink userId={friend.following.id}>
            {friend.following.name}
            {friend.following.verified ? (
              <>
                {" "}
                <VerifiedIcon />
              </>
            ) : null}
          </UserLink>
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {friend.following.username}
        </BasicTableCell>
      </BasicTableRow>
    )
  }

  // Followers
  if (!friend.following) {
    return (
      <BasicTableRow key={friend.id}>
        <BasicTableCell component="th" scope="row">
          <Avatar
            src={`${config.assetsBase}/${
              friend.user.profile_photo
                ? friend.user.profile_photo
                : "users/null.png"
            }`}
          />
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <UserLink userId={friend.user.id}>
            {friend.user.name}
            {friend.user.verified ? (
              <>
                {" "}
                <VerifiedIcon />
              </>
            ) : null}
          </UserLink>
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {friend.user.username}
        </BasicTableCell>
      </BasicTableRow>
    )
  }

  return null
}

export default UserFriendRow
