import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreateContest, Contest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateContest = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const edit = useCallback(
    (id: number, contest: CreateContest): Promise<Contest> => {
      return makeRequest<Contest>(
        `${config.apiUrl}/internal/v1/contests/${id}`,
        {
          method: "PUT",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...contest }),
        }
      )
    },
    [authBearer]
  )

  const updateContest = useCallback(
    async (id: number, contest: CreateContest, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await edit(id, contest)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [edit, setLoading, setError]
  )

  return { updateContest, loading, error }
}

export default useUpdateContest
