import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { User } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetUserById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<User>()

  const request = useCallback(
    (userId: number): Promise<User> => {
      return makeRequest<User>(`${config.apiUrl}/internal/v1/users/${userId}`, {
        headers: {
          authorization: `Bearer ${authBearer}`,
        },
      })
    },
    [authBearer]
  )

  const getUserById = useCallback(
    async (userId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(userId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getUserById,
    user: data,
  }
}

export default useGetUserById
