import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { LeaderboardUser } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetReportedUsers = (defaultRowsPerPage = 100) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<LeaderboardUser>>()

  const [searchValue, setSearchValue] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const [startOfWeek, setStartOfWeek] = useState<string | null>(null)
  const [endOfWeek, setEndOfWeek] = useState<string | null>(null)
  const [excludeMinors, setExcludeMinors] = useState<boolean | null>(false)
  const [excludeTeamScavenge, setExcludeTeamScavenge] = useState<
    boolean | null
  >(false)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getLeaderboard(startOfWeek, endOfWeek, excludeMinors, excludeTeamScavenge)
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback(
    (
      startOfWeek: string | null,
      endOfWeek: string | null,
      excludeMinors: boolean | null,
      excludeTeamScavenge: boolean | null
    ): Promise<PagedResponse<LeaderboardUser>> => {
      return makeRequest<PagedResponse<LeaderboardUser>>(
        `${config.apiUrl}/internal/v1/leaderboard/weekly${
          startOfWeek && endOfWeek
            ? `?startOfWeek=${startOfWeek}&endOfWeek=${endOfWeek}&`
            : "?"
        }excludeMinors=${excludeMinors}&excludeTeamScavenge=${excludeTeamScavenge}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getLeaderboard = useCallback(
    async (
      startOfWeek: string | null,
      endOfWeek: string | null,
      excludeMinors: boolean | null,
      excludeTeamScavenge: boolean | null
    ) => {
      setSearchValue(searchValue)
      setStartOfWeek(startOfWeek || null)
      setEndOfWeek(startOfWeek || null)
      setExcludeMinors(excludeMinors || null)
      setExcludeTeamScavenge(excludeTeamScavenge || null)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(
          startOfWeek,
          endOfWeek,
          excludeMinors,
          excludeTeamScavenge
        )
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getLeaderboard,
    leaderboard: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetReportedUsers
