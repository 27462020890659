import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Notification } from "../../../types"

type Props = {
  notification: Notification
}

const UserNotificationRow: FunctionComponent<Props> = ({ notification }) => {
  return (
    <BasicTableRow key={notification.id}>
      <BasicTableCell component="th" scope="row">
        <img src={`${notification.asset}`} width="50" height="50" />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.type}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.message}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.in_app.toString()}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.push.toString()}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.read.toString()}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {notification.created_at}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserNotificationRow
