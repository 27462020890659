import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Challenge } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetChallenges = (defaultRowsPerPage = 25) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<Challenge>>()

  const [searchValue, setSearchValue] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getChallenges()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback((): Promise<PagedResponse<Challenge>> => {
    let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}`
    return makeRequest<PagedResponse<Challenge>>(
      `${config.apiUrl}/internal/v1/challenges?${queryParams}`,
      {
        headers: {
          authorization: `Bearer ${authBearer}`,
        },
      }
    )
  }, [authBearer, page, rowsPerPage])

  const getChallenges = useCallback(async () => {
    setSearchValue(searchValue)
    setLoading(true)
    setError(null)
    let results
    try {
      results = await request()
      setData(results)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

    return results
  }, [request, setLoading, setError])

  return {
    loading,
    error,
    getChallenges,
    challenges: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetChallenges
