import React, { createContext, useContext, useState } from "react"
import { GoogleLoginResponse } from "react-google-login"
import config from "../config"
import { Employee } from "../types"
import makeRequest from "../utils/make-request"

type AuthContext = {
  getToken: () => string | null
  getUser: () => Employee | null
  isAuthenticated: () => boolean
  handleLogin: (googleLoginResponse: GoogleLoginResponse) => Promise<void>
  handleLogout: () => void
  hasScope: (scope: string) => boolean
}

const AuthContext = createContext(null as any as AuthContext)

const apiUrl = config.apiUrl

export const AuthProvider = ({ children }: { children: any }) => {
  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<Employee | null>(null)

  const getToken = () => token
  const getUser = () => user
  const isAuthenticated = () => !!user
  const hasScope = (scope: string) =>
    !!user ? user.scopes.includes(scope) : false

  const handleLogin = async (
    googleLoginResponse: GoogleLoginResponse
  ): Promise<void> => {
    initializeTokenRefresh(googleLoginResponse)
    const authResponse = googleLoginResponse.getAuthResponse()
    const idToken = authResponse.id_token
    setToken(idToken)

    const userResponse = await getUserForToken(idToken)
    setUser(userResponse)
  }

  const handleLogout = (): void => {
    setToken(null)
    setUser(null)
  }

  const initializeTokenRefresh = (googleLoginResponse: GoogleLoginResponse) => {
    const getRefreshTiming = (expiresIn: number) => (expiresIn || 3595) * 1000

    let refreshTiming = getRefreshTiming(
      googleLoginResponse.tokenObj.expires_in
    )

    const refreshToken = async () => {
      const newAuthResponse = await googleLoginResponse.reloadAuthResponse()

      setToken(newAuthResponse.id_token)

      refreshTiming = getRefreshTiming(newAuthResponse.expires_in)
      setTimeout(refreshToken, refreshTiming)
    }

    setTimeout(refreshToken, refreshTiming)
  }

  const value = {
    getToken,
    getUser,
    isAuthenticated,
    handleLogin,
    handleLogout,
    hasScope,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

const getUserForToken = async (token: string): Promise<Employee> =>
  makeRequest<Employee>(`${apiUrl}/internal/me`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
