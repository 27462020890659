import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { AssetModeration } from "../../../types"
import ProfilePhotoModerationRow from "./ProfilePhotoModerationRow"

interface ProfilePhotoModerationTableProps {
  assets: AssetModeration[]
  paginationData: PaginationData
}

const getRows = (assets: AssetModeration[]) => {
  return assets.map((item, index) => {
    return <ProfilePhotoModerationRow key={index} asset={item} />
  })
}

const columns: string[] = ["User", "Asset", "Approve", "Reject", "Created"]

const ProfilePhotoModerationTable = ({
  assets,
  paginationData,
}: ProfilePhotoModerationTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(assets)}
      paginationOptions={paginationData}
    />
  )
}

export default ProfilePhotoModerationTable
