import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import ReportedPostsTable from "./components/ReportedPostsTable"
import useGetReportedPosts from "./hooks/useGetReportedPosts"

const ReportedPostsScreen: FunctionComponent = () => {
  const { reports, paginationData, getReportedPosts, error, loading } =
    useGetReportedPosts()

  useEffect(() => {
    getReportedPosts()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Reported Posts">
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ReportedPostsTable reports={reports} paginationData={paginationData} />
      )}
    </ScreenContainer>
  )
}

export default ReportedPostsScreen
