import React, { FunctionComponent, useCallback } from "react"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { useAuth } from "../../services/auth-service"

const ProtectedRoute: FunctionComponent<any> = ({ children }) => {
  const auth = useAuth()
  const isAuthenticated = !!auth.isAuthenticated()
  const [searchParams] = useSearchParams()

  const location = useLocation()

  const getSearchParams = useCallback(() => {
    const searchParameters: any = []
    const searchParamEntries = searchParams.entries()
    let lock = false

    while (!lock) {
      const entry = searchParamEntries.next()
      if (!!entry.done) {
        lock = true
      }
      const value = entry.value
      if (value?.length) {
        searchParameters.push({ name: value[0], value: value[1] })
      }
    }

    return searchParameters
  }, [searchParams])

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        replace
        state={{
          redirectPath: location?.pathname,
          searchParams: getSearchParams(),
        }}
      />
    )
  } else {
    return children
  }
}

export default ProtectedRoute
