import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Post } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPostById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Post>()

  const request = useCallback(
    (postId: number): Promise<Post> => {
      return makeRequest<Post>(`${config.apiUrl}/internal/v1/posts/${postId}`, {
        headers: {
          authorization: `Bearer ${authBearer}`,
        },
      })
    },
    [authBearer]
  )

  const getPostById = useCallback(
    async (postId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(postId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getPostById,
    post: data,
  }
}

export default useGetPostById
