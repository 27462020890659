import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Event } from "../../../types"
import EventRow from "./EventRow"

interface Props {
  events: Event[]
  paginationData: PaginationData
}

const getRows = (events: Event[]) => {
  return events.map((event, index) => {
    return <EventRow key={index} event={event} />
  })
}

const columns: string[] = ["ID", "Type", "Queue", "User ID", "Body", "Created"]

const EventsTable = ({ events, paginationData }: Props) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(events)}
      paginationOptions={paginationData}
    />
  )
}

export default EventsTable
