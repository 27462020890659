import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Hunt } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetHunts = (defaultRowsPerPage = 25) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<Hunt>>()

  const [searchValue, setSearchValue] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getHunts(searchValue)
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const fetch = useCallback(
    (searchValue: string): Promise<PagedResponse<Hunt>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${
        rowsPerPage * page
      }&query=${searchValue}`
      return makeRequest<PagedResponse<Hunt>>(
        `${config.apiUrl}/internal/v1/hunts?${queryParams}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getHunts = useCallback(
    async (searchValue: string) => {
      setSearchValue(searchValue)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await fetch(searchValue)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [fetch, setLoading, setError]
  )

  return {
    loading,
    error,
    getHunts,
    hunts: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetHunts
