import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Hunt } from "../../../types"

type Props = {
  hunt: Hunt
}

const HuntRow: FunctionComponent<Props> = ({ hunt }) => {
  return (
    <BasicTableRow key={hunt.id}>
      <BasicTableCell component="th" scope="row">
        {hunt.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {hunt.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {hunt.code}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(hunt.is_live)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(hunt.is_private)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(hunt.is_seed_game)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {hunt.created_at}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default HuntRow
