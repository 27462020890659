import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { PointHistory } from "../../../types"
import UserPointHistoryRow from "./UserPointHistoryRow"

interface UserPointHistoryTableProps {
  pointHistory: PointHistory[]
  paginationData: PaginationData
}

const getRows = (pointHistory: PointHistory[]) => {
  return pointHistory.map((pointHistoryItem, index) => {
    return <UserPointHistoryRow key={index} pointHistory={pointHistoryItem} />
  })
}

const columns: string[] = ["Source", "Value", "Date"]

const UserPointHistoryTable = ({
  pointHistory,
  paginationData,
}: UserPointHistoryTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(pointHistory)}
      paginationOptions={paginationData}
    />
  )
}

export default UserPointHistoryTable
