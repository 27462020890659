import { Button } from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import AIChallengeDialog from "./components/AIChallengeDialog"
import ChallengeDialog from "./components/ChallengeDialog"
import ChallengesTable from "./components/ChallengesTable"
import useGetChallenges from "./hooks/useGetChallenges"

const ChallengesScreen: FunctionComponent = () => {
  const [open, setOpen] = React.useState(false)
  const [openAIGenerate, setOpenAIGenerate] = React.useState(false)

  const { challenges, paginationData, getChallenges, error, loading } =
    useGetChallenges()

  useEffect(() => {
    getChallenges()
    // eslint-disable-next-line
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleAIClickOpen = () => {
    setOpenAIGenerate(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenAIGenerate(false)
    getChallenges()
  }

  return (
    <ScreenContainer title="Challenges">
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        sx={{ my: "12px", width: "300px" }}
      >
        Create Challenge
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAIClickOpen}
        sx={{ mb: "12px", width: "300px" }}
      >
        Generate Prompts
      </Button>
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ChallengesTable
          challenges={challenges}
          paginationData={paginationData}
        />
      )}
      {open ? <ChallengeDialog isOpen={open} close={handleClose} /> : null}
      {openAIGenerate ? (
        <AIChallengeDialog isOpen={openAIGenerate} close={handleClose} />
      ) : null}
    </ScreenContainer>
  )
}

export default ChallengesScreen
