import { Box, Button, TextField } from "@mui/material"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Headline } from "./Typography"

interface SearchInputProps {
  title?: string
  label: string
  handleSearch: (props: any) => void
  inputType?: string
  disabledWhenEmpty?: boolean
  defaultValue?: string | null
}

const SearchInput: FunctionComponent<SearchInputProps> = ({
  title,
  label,
  handleSearch,
  inputType = "text",
  disabledWhenEmpty,
  defaultValue,
}) => {
  const [searchValue, setSearchValue] = useState<number | string>("")

  useEffect(() => {
    if (!!defaultValue) {
      setSearchValue(defaultValue)
    }
  }, [defaultValue])

  const handleKeyDown = useCallback(
    (e: any) => {
      if (disabledWhenEmpty && !searchValue) {
        return
      }
      if (e.key === "Enter") {
        handleSearch(searchValue)
      }
    },
    [handleSearch, searchValue, disabledWhenEmpty]
  )

  const handleChange = useCallback(
    (e: any) => {
      setSearchValue(e.target.value)
    },
    [setSearchValue]
  )

  const onHandleSearch = useCallback(
    (e: any) => {
      e.preventDefault()
      if (disabledWhenEmpty && !searchValue) {
        return
      }
      handleSearch(searchValue)
    },
    [handleSearch, searchValue, disabledWhenEmpty]
  )

  return (
    <Box
      sx={{
        flexDirection: "column",
        flex: 1,
      }}
      display="flex"
      width="100%"
    >
      {title && <Headline sx={{ pb: "7px" }}>{title}</Headline>}
      <TextField
        sx={{ width: "100%" }}
        InputProps={{ inputProps: { min: 1 } }}
        variant="outlined"
        label={label}
        onKeyDown={handleKeyDown}
        type={inputType}
        onChange={handleChange}
        value={searchValue}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={disabledWhenEmpty && !searchValue}
        sx={{ width: "100%", mt: "16px" }}
        onClick={onHandleSearch}
      >
        Search
      </Button>
    </Box>
  )
}

export default SearchInput
