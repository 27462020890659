import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Reward } from "../../../types"
import UserRewardRow from "./UserRewardRow"

interface Props {
  rewards: Reward[]
  paginationData: PaginationData
}

const getRows = (rewards: Reward[]) => {
  return rewards.map((item, index) => {
    return <UserRewardRow key={index} reward={item} />
  })
}

const columns: string[] = ["Amount", "Status", "Source", "Created"]

const UserRewardsTable = ({ rewards, paginationData }: Props) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(rewards)}
      paginationOptions={paginationData}
    />
  )
}

export default UserRewardsTable
