import React from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { IPAddressUser } from "../../../types"
import IPAddressUserRow from "./IPAddressUserRow"

interface UsersWithIPAddressTableProps {
  users: IPAddressUser[]
}

const getRows = (users: IPAddressUser[]) => {
  return users.map((user, index) => {
    return <IPAddressUserRow key={index} user={user.user} />
  })
}

const columns: string[] = ["ID", "Avatar", "Name", "Username"]

const UsersWithIPAddressTable = ({ users }: UsersWithIPAddressTableProps) => {
  return <BasicTable columns={columns} rows={getRows(users)} />
}

export default UsersWithIPAddressTable
