import enforceError from "./enforce-error"

const makeRequest = async <T extends unknown>(
  input: RequestInfo,
  init?: RequestInit,
  timeout = 60000
): Promise<T> => {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), timeout)

  init = {
    ...init,
    signal: controller.signal,
  }
  let response
  try {
    response = await fetch(input, init)
    clearTimeout(timeoutId)
    if (!response.ok) {
      const responseMessage = await response.text()
      const parsedJSON = JSON.parse(responseMessage)
      const errorMessage = parsedJSON.message
      throw RequestError.forStatus(response.status, response, errorMessage)
    }

    const textResponse = await response.text()
    return textResponse ? JSON.parse(textResponse) : {}
  } catch (err) {
    const error = enforceError(err)
    if (error.message === "Fetch is aborted") {
      throw RequestError.forStatus(408)
    } else {
      throw error
    }
  }
}

export default makeRequest

export type PagedResponse<T> = {
  data: T[]
  pagination: {
    page_count: number
    total_count: number
    page_limit: number
    page_offset: number
  }
}

export class RequestError extends Error {
  status: number
  message: string

  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }

  static forStatus(status: number, response?: Response, errorMessage?: string) {
    const message = ((httpStatus: number, response?: Response) => {
      switch (httpStatus) {
        case 400:
          return `${errorMessage ? errorMessage : "bad request"}`
        case 401:
          return `${errorMessage ? errorMessage : "unauthorized"}`
        case 403:
          return `${errorMessage ? errorMessage : "forbidden"}`
        case 404:
          return `${errorMessage ? errorMessage : "not found"}`
        case 408:
          return `${errorMessage ? errorMessage : "request timed out"}`
        case 409:
          return `${errorMessage ? errorMessage : "conflict"}`
        case 500:
          return `${errorMessage ? errorMessage : "something went wrong"}`
        default:
          return `${errorMessage ? errorMessage : "something went wrong"}`
      }
    })(status, response)

    return new RequestError(status, message)
  }
}
