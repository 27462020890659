import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material"
import React, {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useCallback,
} from "react"
import BasicTableCell from "./BasicTableCell"
import BasicTableRow from "./BasicTableRow"

export interface PaginationData {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: any, page: number) => void
  onRowsPerPageChange: (event: any) => void
}
interface BasicTableProps {
  columns: (string | ReactNode)[]
  rows: (string | ReactNode)[]
  paginationOptions?: PaginationData
  ColumnHeader?: ComponentType
}

const BasicTable: FunctionComponent<BasicTableProps> = ({
  columns,
  rows,
  paginationOptions,
  ColumnHeader = BasicTableCell,
}) => {
  const getColumns = useCallback(() => {
    return columns.map((column, index) => (
      <ColumnHeader key={index}>{column}</ColumnHeader>
    ))
  }, [columns, ColumnHeader])

  return (
    <TableContainer component={Paper}>
      {paginationOptions && (
        <TablePagination
          component="div"
          count={paginationOptions?.count || 0}
          page={paginationOptions?.page}
          onPageChange={paginationOptions?.onPageChange}
          rowsPerPage={paginationOptions?.rowsPerPage}
          onRowsPerPageChange={paginationOptions?.onRowsPerPageChange}
        />
      )}
      <Table stickyHeader>
        <TableHead>
          <BasicTableRow>{getColumns()}</BasicTableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
