import React, { FunctionComponent, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useGetUserById from "./hooks/useGetUserById"
import Avatar from "../../components/shared/Avatar"
import config from "../../config"
import { Title1 } from "../../components/shared/Typography"
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Typography,
} from "@mui/material"
import { VerifiedIcon } from "@primer/octicons-react"
import { formatDate } from "../../utils/formatters"
import UpdateUserDialog from "./components/UpdateUserDialog"
import useGetUserPointHistory from "./hooks/useGetUserPointHistory"
import UserPointHistoryTable from "./components/UserPointHistoryTable"
import useGetUserNotes from "./hooks/useGetUserNotes"
import UserNotesTable from "./components/UserNotesTable"
import CreateUserNoteDialog from "./components/CreateUserNoteDialog"
import IPLink from "./components/IPLink"

type Params = {
  userId?: string
}

const UserDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const userId = Number(params.userId)

  const [editOpen, setEditOpen] = useState(false)
  const [createNoteOpen, setCreateNoteOpen] = useState(false)

  const { getUserById, loading, error, user } = useGetUserById()
  const {
    getUserNotes,
    userNotes,
    paginationData: userNotesPagination,
  } = useGetUserNotes()
  const {
    getUserPointHistory,
    pointHistory,
    paginationData: pointHistoryPagination,
  } = useGetUserPointHistory()

  const handleEditOpen = () => {
    setEditOpen(true)
  }

  const handleEditClose = async () => {
    setEditOpen(false)
    await getUserById(userId)
  }

  const handleCreateNoteOpen = () => {
    setCreateNoteOpen(true)
  }

  const handleCreateNoteClose = async () => {
    setCreateNoteOpen(false)
    await getUserNotes(userId)
  }

  useEffect(() => {
    getUserById(userId)
    getUserNotes(userId)
    getUserPointHistory(userId)
    // eslint-disable-next-line
  }, [])

  if (!user) {
    return <Title1>User not found</Title1>
  }

  const birthdateMonth = user.birth_month
  const birthdateDay = user.birth_day
  const birthdateYear = user.birth_year

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const formattedBirthdate =
    birthdateMonth && birthdateDay && birthdateYear
      ? `${monthNames[birthdateMonth - 1]} ${birthdateDay}, ${birthdateYear} (${
          user.age
        } years old)`
      : "Birthday not set"

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? <LoadingSpinner /> : null}
      <Card sx={{ minWidth: 275, margin: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Avatar
                src={`${config.assetsBase}/${
                  user.profile_photo ? user.profile_photo : "/users/null.png"
                }`}
                sx={{
                  height: 150,
                  width: 150,
                  borderRadius: user.type === "personal" ? "50%" : "10%",
                  border: "2px solid #1DA1F2",
                  marginBottom: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4">
                {user.name}
                {user.verified ? <VerifiedIcon size={28} /> : null}
              </Typography>
              {user.is_plus && (
                <Chip
                  label="Plus"
                  sx={{
                    backgroundColor: "#FFD700",
                    color: "white",
                    borderRadius: 2,
                    marginRight: 0.5,
                  }}
                />
              )}
              {user.is_shadow_banned && (
                <Chip
                  label="Shadowbanned"
                  sx={{
                    backgroundColor: "warning.main",
                    color: "white",
                    borderRadius: 2,
                    marginRight: 0.5,
                  }}
                />
              )}
              {user.is_private && (
                <Chip
                  label="Private"
                  sx={{
                    backgroundColor: "pink",
                    color: "white",
                    borderRadius: 2,
                    marginRight: 0.5,
                  }}
                />
              )}
              {user.type === "business" && (
                <Chip
                  label="Business"
                  sx={{
                    backgroundColor: "gold",
                    color: "white",
                    borderRadius: 2,
                    marginRight: 0.5,
                  }}
                />
              )}
              {user.is_reserved && (
                <Chip
                  label="Reserved"
                  sx={{
                    backgroundColor: "blueviolet",
                    color: "white",
                    borderRadius: 2,
                    marginRight: 0.5,
                  }}
                />
              )}
              <Typography variant="subtitle1">@{user.username}</Typography>
              <Typography variant="body1">
                {user?.bio?.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
              <Typography variant="body1">{user.points} Points</Typography>
              <Typography variant="body1">{formattedBirthdate}</Typography>
              {user.website && (
                <Typography variant="body1">{user.website}</Typography>
              )}
              <Typography variant="body1">
                Joined: {formatDate(user.created_at)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={4}>
              <Paper elevation={1} sx={{ padding: 2, marginBottom: 1 }}>
                <Typography variant="h6">Email</Typography>
                <Typography variant="body2">{user.email}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={1} sx={{ padding: 2, marginBottom: 1 }}>
                <Typography variant="h6">Primary IP</Typography>
                <IPLink ipAddress={user.primary_ip}>
                  <Typography variant="body2">{user.primary_ip}</Typography>
                </IPLink>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={1} sx={{ padding: 2, marginBottom: 1 }}>
                <Typography variant="h6">Analytics ID</Typography>
                <Typography variant="body2">{user.analytics_id}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                onClick={handleEditOpen}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Button
                component={Link}
                to={`/users/${user.id}/followers`}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Followers
              </Button>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Button
                component={Link}
                to={`/users/${user.id}/following`}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Following
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                component={Link}
                to={`/users/${user.id}/posts`}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Posts
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                component={Link}
                to={`/users/${user.id}/rewards`}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Rewards
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                component={Link}
                to={`/users/${user.id}/subscriptions`}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Subscriptions
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                component={Link}
                to={`/users/${user.id}/notifications`}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Notifications
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, margin: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                alignItems="center"
                spacing={1}
                marginBottom={1.4}
              >
                <Grid item>
                  <Typography variant="h6">Notes</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={handleCreateNoteOpen} variant="contained">
                    Create Note
                  </Button>
                </Grid>
              </Grid>
              <UserNotesTable
                userNotes={userNotes}
                paginationData={userNotesPagination}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Point History
              </Typography>
              <UserPointHistoryTable
                pointHistory={pointHistory}
                paginationData={pointHistoryPagination}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {editOpen ? (
        <UpdateUserDialog
          isOpen={editOpen}
          close={handleEditClose}
          user={user}
        />
      ) : null}
      {createNoteOpen ? (
        <CreateUserNoteDialog
          isOpen={createNoteOpen}
          close={handleCreateNoteClose}
          userId={user.id}
        />
      ) : null}
    </ScreenContainer>
  )
}

export default UserDetailsScreen
