import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useSetContestWinners from "../hooks/useSetContestWinners"
import useGetLeaderboard from "../../Leaderboard/hooks/useGetLeaderboard"
import { Contest, LeaderboardUser } from "../../../types"
import { convertCentsToDollar } from "../../../utils/formatters"

interface Props {
  isOpen: boolean
  close: () => void
  contest: Contest
}

const ContestWinnersDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  contest,
}) => {
  const [userIds, setUserIds] = useState<number[]>([])
  const [selectedUsers, setSelectedUsers] = useState<LeaderboardUser[]>([])
  const [startOfWeek, setStartOfWeek] = useState<null | string>(null)
  const [endOfWeek, setEndOfWeek] = useState<null | string>(null)

  const { setContestWinners, error, loading } = useSetContestWinners()
  const { getLeaderboard, leaderboard } = useGetLeaderboard()

  const validationSchema = Yup.object().shape({})

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  const handleUserSelection = (userId: number) => {
    if (userIds.includes(userId)) {
      // Remove user from the selection
      setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== userId))
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user.user.id !== userId)
      )
    } else {
      // Add user to the selection
      setUserIds((prevUserIds) => [...prevUserIds, userId])
      const selectedUser = leaderboard.find((user) => user.user.id === userId)
      if (selectedUser) {
        setSelectedUsers((prevSelectedUsers) => [
          ...prevSelectedUsers,
          selectedUser,
        ])
      }
    }
  }

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await setContestWinners(contest.id, userIds, handleSetSuccess)
    },
  })

  const handleSetSuccess = () => {
    toast.success("Successfully set contest winners")
    close()
  }

  const fetchLeaderboard = () => {
    getLeaderboard(startOfWeek, endOfWeek, true, true)
  }

  useEffect(() => {
    // Fetch leaderboard when the component mounts
    fetchLeaderboard()
  }, [])

  useEffect(() => {
    // Fetch leaderboard when startOfWeek or endOfWeek changes
    fetchLeaderboard()
  }, [startOfWeek, endOfWeek])

  return (
    <Dialog
      title={"Contest Winners"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <Typography>Contest Name: {contest.title}</Typography>
          <Typography>Max Winners: {contest.max_winners}</Typography>
          <Typography>
            Reward: ${convertCentsToDollar(contest.prize_value)}
          </Typography>
          <TextField
            label="Start of Week"
            type="date"
            value={startOfWeek || ""}
            onChange={(e) => setStartOfWeek(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End of Week"
            type="date"
            value={endOfWeek || ""}
            onChange={(e) => setEndOfWeek(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.map((user) => (
                <TableRow key={user.rank}>
                  <TableCell>{user.rank}</TableCell>
                  <TableCell>{user.user.id}</TableCell>
                  <TableCell>{user.user.name}</TableCell>
                  <TableCell>{user.user.username}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleUserSelection(user.user.id)}
                      color={
                        userIds.includes(user.user.id) ? "secondary" : "primary"
                      }
                      variant="contained"
                    >
                      {userIds.includes(user.user.id) ? "Remove" : "Select"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      }
      buttonContent={
        <>
          <div>
            <strong>Selected Users:</strong>
            <ul>
              {selectedUsers.map((user) => (
                <li
                  key={user.user.id}
                >{`ID: ${user.user.id}, Name: ${user.user.name}`}</li>
              ))}
            </ul>
          </div>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {"Reward Winners"}
          </Button>
        </>
      }
    />
  )
}

export default ContestWinnersDialog
