import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { PointHistory } from "../../../types"
import { formatDate } from "../../../utils/formatters"

type UserFriendRowProps = {
  pointHistory: PointHistory
}

const UserPointHistoryRow: FunctionComponent<UserFriendRowProps> = ({
  pointHistory,
}) => {
  return (
    <BasicTableRow key={pointHistory.id}>
      <BasicTableCell component="th" scope="row">
        {pointHistory.source}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {pointHistory.value}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(pointHistory.created_at)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserPointHistoryRow
