import { Button, FormLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { Contest } from "../../../types"
import useCreateContest from "../hooks/useCreateContest"
import useUpdateContest from "../hooks/useUpdateContest"

interface Props {
  isOpen: boolean
  close: () => void
  contest?: Contest | undefined
}

const ContestDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  contest,
}) => {
  const { createContest, error, loading } = useCreateContest()
  const {
    updateContest,
    error: updateError,
    loading: updateLoading,
  } = useUpdateContest()

  const validationSchema = Yup.object().shape({
    kicker: Yup.string(),
    kicker_color: Yup.string(),
    title: Yup.string(),
    title_color: Yup.string(),
    subtitle: Yup.string(),
    subtitle_color: Yup.string(),
    description: Yup.string(),
    description_color: Yup.string(),
    background_color: Yup.string(),
    cta_visible: Yup.boolean(),
    cta_background_color: Yup.string(),
    cta_title: Yup.string(),
    cta_title_color: Yup.string(),
    cta_type: Yup.string(),
    cta_action: Yup.string(),
    prize_value: Yup.number(),
    prize_type: Yup.string(),
    max_winners: Yup.number(),
    active: Yup.boolean(),
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      kicker: "",
      kicker_color: "#ffffff",
      title: "",
      title_color: "#ffffff",
      subtitle: "",
      subtitle_color: "#ffffff",
      description: "",
      description_color: "#ffffff",
      background_color: "#333333",
      cta_visible: false,
      cta_background_color: "#ffffff",
      cta_title: "",
      cta_title_color: "#000000",
      cta_type: "",
      cta_action: "",
      prize_value: 0,
      prize_type: "",
      max_winners: 0,
      active: true,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!contest) {
        await createContest(values, handleCreateSuccess)
      } else {
        await updateContest(contest.id, values, handleUpdateSuccess)
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success("Successfully created contest")
    close()
  }

  const handleUpdateSuccess = () => {
    toast.success("Successfully updated contest")
    close()
  }

  useEffect(() => {
    if (contest) {
      formik.setValues({
        kicker: contest.kicker || "",
        kicker_color: contest.kicker_color || "#ffffff",
        title: contest.title || "",
        title_color: contest.title_color || "#ffffff",
        subtitle: contest.subtitle || "",
        subtitle_color: contest.subtitle_color || "#ffffff",
        description: contest.description || "",
        description_color: contest.description_color || "#ffffff",
        background_color: contest.background_color || "#333333",
        cta_visible: contest.cta_visible || false,
        cta_background_color: contest.cta_background_color || "#ffffff",
        cta_title: contest.cta_title || "",
        cta_title_color: contest.cta_title_color || "#000000",
        cta_type: contest.cta_type || "",
        cta_action: contest.cta_action || "",
        prize_value: contest.prize_value || 0,
        prize_type: contest.prize_type || "",
        max_winners: contest.max_winners || 0,
        active: contest.active || true,
      })
    }
  }, [contest])

  return (
    <Dialog
      title={!contest ? "Create Contest" : "Edit Contest"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading || updateLoading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {updateError && <ErrorComponent error={updateError} />}
          <TextField
            autoFocus
            margin="dense"
            id="kicker"
            name="kicker"
            label="Kicker"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.kicker}
          />
          <TextField
            margin="dense"
            id="kicker_color"
            name="kicker_color"
            label="Kicker Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.kicker_color}
          />
          <TextField
            margin="dense"
            id="title"
            name="title"
            label="Title"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <TextField
            margin="dense"
            id="title_color"
            name="title_color"
            label="Title Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.title_color}
          />
          <TextField
            margin="dense"
            id="subtitle"
            name="subtitle"
            label="Subtitle"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.subtitle}
          />
          <TextField
            margin="dense"
            id="subtitle_color"
            name="subtitle_color"
            label="Subtitle Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.subtitle_color}
          />
          <TextField
            margin="dense"
            id="description"
            name="description"
            label="Description"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          <TextField
            margin="dense"
            id="description_color"
            name="description_color"
            label="Description Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.description_color}
          />
          <TextField
            margin="dense"
            id="background_color"
            name="background_color"
            label="Background Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.background_color}
          />
          <FormLabel>CTA Visible</FormLabel>
          <Switch
            id="cta_visible"
            name="cta_visible"
            checked={formik.values.cta_visible}
            onChange={formik.handleChange}
            color="primary"
          />
          <TextField
            margin="dense"
            id="cta_background_color"
            name="cta_background_color"
            label="CTA Background Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.cta_background_color}
          />
          <TextField
            margin="dense"
            id="cta_title"
            name="cta_title"
            label="CTA Title"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.cta_title}
          />
          <TextField
            margin="dense"
            id="cta_title_color"
            name="cta_title_color"
            label="CTA Title Color"
            fullWidth
            variant="outlined"
            type="color"
            onChange={formik.handleChange}
            value={formik.values.cta_title_color}
          />
          <TextField
            margin="dense"
            id="cta_type"
            name="cta_type"
            label="CTA Type"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.cta_type}
          />
          <TextField
            margin="dense"
            id="cta_action"
            name="cta_action"
            label="CTA Action"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.cta_action}
          />
          <TextField
            margin="dense"
            id="prize_value"
            name="prize_value"
            label="Prize Value"
            fullWidth
            variant="outlined"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.prize_value}
          />
          <TextField
            margin="dense"
            id="prize_type"
            name="prize_type"
            label="Prize Type"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.prize_type}
          />
          <TextField
            margin="dense"
            id="max_winners"
            name="max_winners"
            label="Max Winners"
            fullWidth
            variant="outlined"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.max_winners}
          />
          <FormLabel>Contest Active</FormLabel>
          <Switch
            id="active"
            name="active"
            checked={formik.values.active}
            onChange={formik.handleChange}
            color="primary"
          />
          <br />
          <FormLabel>Preview:</FormLabel>
          <div
            style={{
              backgroundColor: formik.values.background_color,
              padding: 10,
              width: "100%",
              borderRadius: 8,
            }}
          >
            {formik.values.kicker ? (
              <p style={{ color: formik.values.kicker_color, fontSize: 13 }}>
                {formik.values.kicker}
              </p>
            ) : null}
            {formik.values.title ? (
              <p
                style={{
                  color: formik.values.title_color,
                  fontSize: 22,
                  fontWeight: "600",
                }}
              >
                {formik.values.title}
              </p>
            ) : null}
            {formik.values.subtitle ? (
              <p style={{ color: formik.values.subtitle_color, fontSize: 13 }}>
                {formik.values.subtitle}
              </p>
            ) : null}
            {formik.values.cta_visible ? (
              <div
                style={{
                  backgroundColor: formik.values.cta_background_color,
                  height: 35,
                  borderRadius: 8,
                  marginTop: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: formik.values.cta_title_color,
                    textAlign: "center",

                    fontWeight: "600",
                  }}
                >
                  {formik.values.cta_title}
                </p>
              </div>
            ) : null}
          </div>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading}
          >
            {!contest ? "Create Contest" : "Edit Contest"}
          </Button>
        </>
      }
    />
  )
}

export default ContestDialog
