import { createTheme } from "@mui/material/styles"

// TODO: reduce this list by converting things to use mui theme.palette

const theme = createTheme({
  colors: {
    primary: "#000000",
    primaryText: "#000000",
    disabled: "#bbb",
    card: "#f2f2f2",
    border: "#aaa",
    error: "#ff604f",
    warning: "#ff9800",
    successLight: "#81c784",
    successMain: "#66bb6a",
    successDark: "#388e3c",
    appHeader: "#3f51b5",
  },
})

export default theme
