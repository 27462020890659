import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { AIPrompt } from "../../../types"
import { Button } from "@mui/material"
import useCreateChallengeNextDate from "../hooks/useCreateChallengeNextDate"
import { toast } from "react-hot-toast"

type AIChallengePromptRowProps = {
  prompt: AIPrompt
}

const AIChallengePromptRow: FunctionComponent<AIChallengePromptRowProps> = ({
  prompt,
}) => {
  const { createChallengeNextDate, loading, error } =
    useCreateChallengeNextDate()
  const [disabled, setDisabled] = React.useState(false)

  const handleSuccess = () => {
    toast.success("Successfully created challenge")
    setDisabled(true)
  }

  const handleCreate = async () => {
    try {
      await createChallengeNextDate(
        { prompt: prompt.prompt, date: new Date().toString(), active: true },
        handleSuccess
      )
    } catch (err) {
      toast.error(`Error creating: ${error?.message}`)
    }
  }

  return (
    <BasicTableRow key={prompt.prompt}>
      <BasicTableCell component="th" scope="row">
        {prompt.prompt}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button disabled={disabled || loading} onClick={() => handleCreate()}>
          {!disabled ? "Create" : "Created"}
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default AIChallengePromptRow
