import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetFunds from "./hooks/useGetFunds"
import { Button, Card, Typography } from "@mui/material"
import { convertCentsToDollar } from "../../utils/formatters"
import ContestsTable from "./components/ContestsTable"
import useGetContests from "./hooks/useGetContests"
import ContestDialog from "./components/ContestDialog"

const ContestsScreen: FunctionComponent = () => {
  const [open, setOpen] = React.useState(false)
  const { funds, getFunds, error, loading } = useGetFunds()
  const {
    contests,
    getContests,
    paginationData,
    error: contestsError,
    loading: contestsLoading,
  } = useGetContests()

  useEffect(() => {
    getFunds()
    getContests()
    // eslint-disable-next-line
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getContests()
  }

  return (
    <ScreenContainer title="Contests">
      {error || contestsError ? (
        <ErrorComponent error={error || contestsError!} />
      ) : null}
      {loading || contestsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Card sx={{ minWidth: "100%" }}>
            <Typography variant="body1" sx={{ margin: 1 }}>
              <b>Account Balance:</b> $
              {convertCentsToDollar(funds?.account_balance || 0)}
            </Typography>
            <Typography variant="body1" sx={{ margin: 1 }}>
              <b>Pending Payouts:</b> $
              {convertCentsToDollar(funds?.pending_payouts || 0)}
            </Typography>
            <Typography variant="body1" sx={{ margin: 1 }}>
              <b>Expired Balance:</b> $
              {convertCentsToDollar(funds?.expired_balance || 0)}
            </Typography>
            <Typography variant="body1" sx={{ margin: 1 }}>
              <b>Redeemed Balance:</b> $
              {convertCentsToDollar(funds?.redeemed_balance || 0)}
            </Typography>
          </Card>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            sx={{ my: "12px", width: "300px" }}
          >
            Create Contest
          </Button>
          <ContestsTable
            contests={contests}
            paginationData={paginationData}
            onSuccess={getContests}
          />
        </>
      )}
      {open ? <ContestDialog isOpen={open} close={handleClose} /> : null}
    </ScreenContainer>
  )
}

export default ContestsScreen
