import { Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreateUserNote from "../hooks/useCreateUserNote"

interface Props {
  isOpen: boolean
  close: () => void
  userId: number
}

const CreateUserNoteDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  userId,
}) => {
  const { createNote, loading, error } = useCreateUserNote()

  const validationSchema = Yup.object().shape({
    note: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      await createNote(userId, values.note, handleCreateUserNoteSuccess)
    },
  })

  const handleCreateUserNoteSuccess: any = async () => {
    toast.success("Successfully created user note")
    close()
  }

  return (
    <Dialog
      title={`Create User Note`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            margin="dense"
            id="note"
            name="note"
            label="Note"
            fullWidth
            required
            multiline
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.note}
            error={!!formik.errors.note}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Create Note
          </Button>
        </>
      }
    />
  )
}

export default CreateUserNoteDialog
