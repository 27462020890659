import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { IPAddressDetails } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetIPAddressDetails = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<IPAddressDetails>()

  const request = useCallback(
    (ipAddress: string): Promise<IPAddressDetails> => {
      return makeRequest<IPAddressDetails>(
        `${config.apiUrl}/internal/v1/ip-addresses/${ipAddress}/details`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getIPAddressDetails = useCallback(
    async (ipAddress: string) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(ipAddress)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getIPAddressDetails,
    details: data,
  }
}

export default useGetIPAddressDetails
