import { useMediaQuery } from "@mui/material"

const useBreakpoint = () => {
  const xs = useMediaQuery((theme: any) => theme.breakpoints.up("xs"))
  const sm = useMediaQuery((theme: any) => theme.breakpoints.up("sm"))
  const md = useMediaQuery((theme: any) => theme.breakpoints.up("md"))
  const lg = useMediaQuery((theme: any) => theme.breakpoints.up("lg"))
  const xl = useMediaQuery((theme: any) => theme.breakpoints.up("xl"))

  switch (true) {
    case xl:
      return "xl"
    case lg:
      return "lg"
    case md:
      return "md"
    case sm:
      return "sm"
    case xs:
      return "xs"
    default:
      return "xxs"
  }
}

export default useBreakpoint
