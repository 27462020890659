import React, { FunctionComponent } from "react"
import Router from "./components/navigation/Router"
import { AuthProvider } from "./services/auth-service"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DayjsUtils from "@date-io/dayjs"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./constants/theme"
import { BrowserRouter } from "react-router-dom"
import { UserProvider } from "./services/user-service"
import { GoogleOAuthProvider } from "@react-oauth/google"
import config from "./config"
import { Toaster } from "react-hot-toast"
import { LocalizationProvider } from "@mui/lab"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

const App: FunctionComponent = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={config.googleClientId}>
        <AuthProvider>
          <UserProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <Router />
                <Toaster />
              </MuiPickersUtilsProvider>
            </LocalizationProvider>
          </UserProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </BrowserRouter>
)

export default App
