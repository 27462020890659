import { Box } from "@mui/material"
import React, { FunctionComponent, useCallback } from "react"
import { User } from "../../../types"
import UserCard from "./UserCard"

type UserListProps = {
  users: User[]
}

const UserList: FunctionComponent<UserListProps> = ({ users }) => {
  const getUserlist = useCallback((users: User[]) => {
    return users.map((user: User, index: number) => (
      <UserCard key={index} user={user} />
    ))
  }, [])

  return (
    <ul>
      <Box
        sx={{
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "center",
          flexBasis: "100%",
          overflow: "scroll",
        }}
        display="flex"
      >
        {getUserlist(users)}
      </Box>
    </ul>
  )
}

export default UserList
