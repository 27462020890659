import { NavigateNext as NavigateNextIcon } from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import React, { FunctionComponent } from "react"
import Avatar from "../../../components/shared/Avatar"
import Card, { CardColumn, CardRow } from "../../../components/shared/Card"
import CopyIcon from "../../../components/shared/CopyIcon"
import { TextBody, Title3 } from "../../../components/shared/Typography"
import config from "../../../config"
import { User } from "../../../types"
import UserLink from "./UserLink"
import { VerifiedIcon } from "@primer/octicons-react"

type UserCardProps = {
  user: User
}

const UserCard: FunctionComponent<UserCardProps> = ({ user }) => {
  return (
    <Container>
      <CardColumn flexBasis="10%">
        <Avatar
          src={`${config.assetsBase}/${
            user.profile_photo ? user.profile_photo : "/users/null.png"
          }`}
          alt="User Profile"
          sx={{ borderRadius: user.type === "personal" ? "50%" : "10%" }}
        />
      </CardColumn>
      <CardColumn flexBasis="80%" pl="10px">
        <CardRow>
          <Title3>Name: </Title3>
          <StyledText>
            {`${user.name}`}
            {user.verified ? (
              <>
                {" "}
                <VerifiedIcon />
              </>
            ) : null}
          </StyledText>
        </CardRow>
        <CardRow>
          <Title3>Username: </Title3>
          <StyledText>{`${user.username}`}</StyledText>
        </CardRow>
        <CardRow>
          <Title3>Email: </Title3>
          <StyledText>{`${user.email}`}</StyledText>
        </CardRow>
        <CardRow>
          <Title3>User ID: </Title3>
          <StyledText>{`${user.id}`}</StyledText>
          <CopyIcon content={user.id} />
        </CardRow>
      </CardColumn>
      <CardColumn flexBasis="10%">
        <UserLink userId={user.id}>
          <NavigateNextIcon fontSize="large" />
        </UserLink>
      </CardColumn>
    </Container>
  )
}

const Container = styled(Card)`
  margin: 20px;
  flex: 1;
  flex-basis: 40%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
`

const StyledText = styled(TextBody)`
  margin-left: 5px;
  margin-right: 5px;
`

export default UserCard
