import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import UserDeletionRequestsTable from "./components/UserDeletionRequestsTable"
import useGetUserDeletionRequests from "./hooks/useGetUserDeletionRequests"

const UserDeletionRequestsScreen: FunctionComponent = () => {
  const {
    deletionRequests,
    paginationData,
    getDeletionRequests,
    error,
    loading,
  } = useGetUserDeletionRequests()

  useEffect(() => {
    getDeletionRequests()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="User Deletion Requests">
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <UserDeletionRequestsTable
          deletionRequests={deletionRequests}
          paginationData={paginationData}
        />
      )}
    </ScreenContainer>
  )
}

export default UserDeletionRequestsScreen
