import React from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { AIPrompt } from "../../../types"
import AIChallengePromptRow from "./AIChallengePromptRow"

interface AIChallengesTableProps {
  prompts: AIPrompt[]
}

const getRows = (prompts: AIPrompt[]) => {
  return prompts.map((prompt, index) => {
    return <AIChallengePromptRow key={index} prompt={prompt} />
  })
}

const columns: string[] = ["Prompt", "Action"]

const AIChallengesTable = ({ prompts }: AIChallengesTableProps) => {
  return <BasicTable columns={columns} rows={getRows(prompts)} />
}

export default AIChallengesTable
