import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Balance } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetUserBalance = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Balance>()

  const request = useCallback(
    (userId: number): Promise<Balance> => {
      return makeRequest<Balance>(
        `${config.apiUrl}/internal/v1/users/${userId}/rewards/balance`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getUserBalance = useCallback(
    async (userId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(userId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getUserBalance,
    balance: data,
  }
}

export default useGetUserBalance
