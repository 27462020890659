import React, { FunctionComponent, useCallback, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHunts from "./hooks/useGetHunts"
import { useSearchParams } from "react-router-dom"
import SearchInput from "../../components/shared/SearchInput"
import { Box } from "@mui/system"
import HuntsTable from "./components/HuntsTable"
// import LeaderboardTable from "./components/LeaderboardTable"
// import useGetLeaderboard from "./hooks/useGetLeaderboard"

const HuntsScreen: FunctionComponent = () => {
  const { hunts, paginationData, getHunts, error, loading } = useGetHunts()
  let [searchParams, setSearchParams] = useSearchParams()

  const handleSearchFromRouter = useCallback(async () => {
    const term = searchParams.get("term")
    await getHunts(term || "")
  }, [searchParams, getHunts])

  useEffect(() => {
    handleSearchFromRouter()
    // eslint-disable-next-line
  }, [])

  const handleSearchHunts = useCallback(
    async (searchValue: string) => {
      await getHunts(searchValue)
      setSearchParams({ term: searchValue as string })
    },
    [setSearchParams, getHunts]
  )

  return (
    <ScreenContainer title="Hunts">
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput
            handleSearch={handleSearchHunts}
            title={"Search hunts by name"}
            label={"Hunt Search"}
            defaultValue={searchParams.get("term")}
          />
          <HuntsTable hunts={hunts} paginationData={paginationData} />
        </>
      )}
    </ScreenContainer>
  )
}

export default HuntsScreen
