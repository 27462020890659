import React, { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title1, Title3 } from "../../components/shared/Typography"
import { Box, Button } from "@mui/material"
import useGetUserNotifications from "./hooks/useGetUserNotifications"
import UserNotificationsTable from "./components/UserNotificationsTable"
import UserNotificationDialog from "./components/UserNotificationDialog"

type Params = {
  userId?: string
}

const UserNotificationsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const userId = Number(params.userId)

  const [open, setOpen] = React.useState(false)

  const {
    getUserNotifications,
    notifications,
    loading,
    error,
    paginationData,
  } = useGetUserNotifications()

  useEffect(() => {
    getUserNotifications(userId)
    // eslint-disable-next-line
  }, [])

  if (!notifications) {
    return <Title1>Notifications not found for user {userId}</Title1>
  }

  const handleDialogClose = async () => {
    await getUserNotifications(userId)
    setOpen(false)
  }

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
        }}
      ></Box>
      <Title3>User {userId}'s Notifications:</Title3>
      {loading ? <LoadingSpinner /> : null}
      <Button
        style={{ maxWidth: 300 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Create Notification
      </Button>
      <UserNotificationsTable
        notifications={notifications}
        paginationData={paginationData}
      />
      <UserNotificationDialog
        isOpen={open}
        close={handleDialogClose}
        userId={userId}
      />
    </ScreenContainer>
  )
}

export default UserNotificationsScreen
