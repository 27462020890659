import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

export const formatDate = (dateTime: Date, locale = "en-US") => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const timeZoned = dayjs(dateTime).tz("America/New_York")
  return dayjs(timeZoned).format("MM-DD-YY HH:MM a")
}

export function convertCentsToDollar(cents: number) {
  const dollars = cents / 100
  return dollars.toFixed(2) // Returns the dollar amount with 2 decimal places
}
