import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { AssetModeration } from "../../../types"
import { formatDate } from "../../../utils/formatters"
import config from "../../../config"
import { Button } from "@mui/material"
import useUpdateAssetModeration from "../hooks/useUpdateAssetModeration"
import toast from "react-hot-toast"
import UserLink from "./UserLink"

type ProfilePhotoModerationRowProps = {
  asset: AssetModeration
}

const ProfilePhotoModerationRow: FunctionComponent<
  ProfilePhotoModerationRowProps
> = ({ asset }) => {
  const [hidden, setHidden] = useState<boolean>(false)
  const { updateAssetModeration } = useUpdateAssetModeration()

  let approveAction = "Approve"
  let rejectAction = "Reject"

  const handleActionClicked = async (action: string) => {
    switch (action) {
      case "approve":
        toast.loading("Loading...")
        await updateAssetModeration(asset.id, action, handleApproveSuccess)
        break
      case "reject":
        toast.loading("Loading...")
        await updateAssetModeration(asset.id, action, handleRejectSuccess)
        break
      default:
        toast.error("Invalid action clicked")
    }
  }

  const handleApproveSuccess = async () => {
    toast.dismiss()
    toast.success("Successfully approved")
    setHidden(true)
  }

  const handleRejectSuccess = async () => {
    toast.dismiss()
    toast.success("Successfully rejected")
    setHidden(true)
  }

  if (hidden) {
    return null
  }

  switch (asset.type) {
    case "profilePhoto":
      break
    case "post":
      approveAction = "Inapproproate"
      rejectAction = "Not Inapproproate"
      break
    default:
      break
  }

  return (
    <BasicTableRow key={asset.id}>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={asset.user_id}>
          {asset.user?.name || ""} ({asset.user?.id || asset.user_id})
        </UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <img
          src={`${config.assetsBase}/${asset.asset}`}
          width="100"
          height="100"
          style={{
            borderRadius: asset.user?.type === "personal" ? "50%" : "10%",
          }}
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button onClick={() => handleActionClicked("approve")}>
          {approveAction}
        </Button>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button onClick={() => handleActionClicked("reject")}>
          {rejectAction}
        </Button>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(asset.created_at)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default ProfilePhotoModerationRow
