import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Event } from "../../../types"
import UserLink from "../../Users/components/UserLink"

type Props = {
  event: Event
}

const EventRow: FunctionComponent<Props> = ({ event }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const bodyLines = JSON.stringify(event.body, null, 2).split("\n")

  return (
    <BasicTableRow key={event.id}>
      <BasicTableCell component="th" scope="row">
        {event.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.type}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.queue}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={event.user_id}>{event.user_id}</UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <pre>
          {isExpanded
            ? JSON.stringify(event.body, null, 2)
            : bodyLines.slice(0, 5).join("\n")}
        </pre>
        {bodyLines.length > 5 && (
          <button onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Show Less" : "Show More"}
          </button>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.created_at}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default EventRow
