import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  TablePagination,
  Chip,
} from "@mui/material"
import config from "../../config"
import UserLink from "../Users/components/UserLink"
import useGetPosts from "./hooks/useGetPosts"

const PostsScreen: FunctionComponent = () => {
  const { posts, paginationData, getPosts, error, loading } = useGetPosts()

  const [imageSize, setImageSize] = useState(0)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function updateImageSize() {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        let imageSize
        if (containerWidth >= 960) {
          imageSize = Math.floor(containerWidth / 6) // set the image size to one-sixth of the container width for large screens (width >= 960px)
        } else if (containerWidth >= 600) {
          imageSize = Math.floor(containerWidth / 5) // set the image size to one-fifth of the container width for medium screens (width >= 600px)
        } else {
          imageSize = Math.floor(containerWidth / 3) // set the image size to one-third of the container width for small screens (width < 600px)
        }
        setImageSize(imageSize)
      }
    }

    window.addEventListener("resize", updateImageSize)
    updateImageSize()

    return () => {
      window.removeEventListener("resize", updateImageSize)
    }
  }, [])

  useEffect(() => {
    getPosts()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Posts">
      <TablePagination
        component="div"
        count={paginationData?.count || 0}
        page={paginationData?.page}
        onPageChange={paginationData?.onPageChange}
        rowsPerPage={paginationData?.rowsPerPage}
        onRowsPerPageChange={paginationData?.onRowsPerPageChange}
      />
      {loading ? <LoadingSpinner /> : null}
      {error ? <ErrorComponent error={error} /> : null}
      <div ref={containerRef}>
        <Grid container spacing={2}>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={post.id}>
              <Card sx={{ height: "100%" }}>
                <CardMedia
                  component="img"
                  style={{
                    height: imageSize,
                    width: imageSize,
                    backgroundSize: "cover",
                  }}
                  image={`${config.assetsBase}/${post.photo}`}
                  alt="Post"
                />
                <CardContent>
                  <div>
                    {post.is_plus_post && (
                      <Chip
                        label="Plus Post"
                        sx={{
                          backgroundColor: "gold",
                          color: "white",
                          borderRadius: 2,
                          marginRight: 0.5,
                        }}
                      />
                    )}
                    {post.user.is_shadow_banned && (
                      <Chip
                        label="Shadowbanned"
                        sx={{
                          backgroundColor: "warning.main",
                          color: "white",
                          borderRadius: 2,
                          marginRight: 0.5,
                        }}
                      />
                    )}
                    {post.deleted_at && (
                      <Chip
                        label="Deleted"
                        sx={{
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: 2,
                          marginRight: 0.5,
                        }}
                      />
                    )}
                    {post.is_flagged && (
                      <Chip
                        label="Flagged"
                        sx={{
                          backgroundColor: "darkslateblue",
                          color: "white",
                          borderRadius: 2,
                          marginRight: 0.5,
                        }}
                      />
                    )}
                  </div>
                  <UserLink userId={post.user_id}>
                    <Typography variant="h6" gutterBottom>
                      {post.is_friends_only ? "🔒 " : "🌐 "}
                      {post.user.name}
                    </Typography>
                  </UserLink>
                  <Typography variant="body1">{post.caption}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </ScreenContainer>
  )
}

export default PostsScreen
