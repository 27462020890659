import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Follower } from "../../../types"
import UserFriendRow from "./UserFriendRow"

interface UserFriendsTableProps {
  friends: Follower[]
  paginationData: PaginationData
}

const getRows = (friends: Follower[]) => {
  return friends.map((friend, index) => {
    return <UserFriendRow key={index} friend={friend} />
  })
}

const columns: string[] = ["Photo", "Name", "Username"]

const UserFriendsTable = ({
  friends,
  paginationData,
}: UserFriendsTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(friends)}
      paginationOptions={paginationData}
    />
  )
}

export default UserFriendsTable
