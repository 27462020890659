import React, { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  ipAddress: string
}

type IPLinkProps = Omit<Props, "to">

const IPLink: FunctionComponent<IPLinkProps> = ({
  ipAddress,
  children,
  ...rest
}) => (
  <Link
    {...rest}
    style={{ textDecoration: "none" }}
    to={`/ip-addresses/${ipAddress}`}
  >
    {children}
  </Link>
)

export default IPLink
