import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UserNote } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateUserNote = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const create = useCallback(
    (userId: number, note: string): Promise<UserNote> => {
      return makeRequest<UserNote>(
        `${config.apiUrl}/internal/v1/users/${userId}/notes`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ note }),
        }
      )
    },
    [authBearer]
  )

  const createNote = useCallback(
    async (userId: number, note: string, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await create(userId, note)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [create, setLoading, setError]
  )

  return { createNote, loading, error }
}

export default useCreateUserNote
