import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Follower } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetUserFollowing = (defaultRowsPerPage = 10) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<Follower>>()

  const [userId, setUserId] = useState<number>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (userId) {
      getUserFollowing(userId)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback(
    (userId: number): Promise<PagedResponse<Follower>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}`
      return makeRequest<PagedResponse<Follower>>(
        `${config.apiUrl}/internal/v1/users/${userId}/following?${queryParams}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getUserFollowing = useCallback(
    async (userId: number) => {
      setUserId(userId)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(userId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getUserFollowing,
    following: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetUserFollowing
