import { styled } from "@mui/material/styles"

const Avatar = styled("img")`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
`

export default Avatar
