import { MobileDatePicker } from "@mui/lab"
import { Button, TextField } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { useFormik } from "formik"
import React, { FunctionComponent, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { Challenge } from "../../../types"
import useCreateChallenge from "../hooks/useCreateChallenge"
import useEditChallenge from "../hooks/useEditChallenge"

interface Props {
  isOpen: boolean
  close: () => void
  challenge?: Challenge | undefined
}

const ChallengeDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  challenge,
}) => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1) // even 32 is acceptable
  const formattedTomorrow = `${
    tomorrow.getMonth() + 1
  }-${tomorrow.getDate()}-${tomorrow.getFullYear()}`

  const { createChallenge, loading, error } = useCreateChallenge()
  const {
    editChallenge,
    loading: editLoading,
    error: editError,
  } = useEditChallenge()
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs(formattedTomorrow)
  )

  const validationSchema = Yup.object().shape({
    prompt: Yup.string().required(),
    date: Yup.string().required(),
    subdomain: Yup.boolean().optional(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue)
  }

  const formik = useFormik({
    initialValues: {
      prompt: "",
      date: formattedTomorrow,
      active: true,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      !challenge
        ? await createChallenge(
            {
              prompt: values.prompt,
              date: String(value?.format("MM-DD-YYYY")),
              active: values.active,
            },
            handleCreateSuccess
          )
        : await editChallenge(
            challenge.id,
            {
              prompt: values.prompt,
              date: String(value?.format("MM-DD-YYYY")),
              active: values.active,
            },
            handleUpdateSuccess
          )
    },
  })

  const handleCreateSuccess: any = async () => {
    toast.success("Successfully created challenge")
    close()
  }

  const handleUpdateSuccess: any = async () => {
    toast.success("Successfully updated challenge")
    close()
  }

  useEffect(() => {
    if (challenge) {
      formik.setValues({
        prompt: challenge.prompt,
        date: `${challenge.date}`,
        active: challenge.active,
      })
      setValue(dayjs(challenge.date))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={!challenge ? "Create Challenge" : "Edit Challenge"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading || editLoading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {editError && <ErrorComponent error={editError} />}
          <TextField
            autoFocus
            margin="dense"
            id="prompt"
            name="prompt"
            label="Challenge Prompt"
            fullWidth
            variant="outlined"
            required
            multiline
            onChange={formik.handleChange}
            value={formik.values.prompt}
            error={!!formik.errors.prompt}
          />
          <MobileDatePicker
            label="Date"
            inputFormat="MM-DD-YYYY"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || editLoading}
          >
            {!challenge ? "Create Challenge" : "Edit Challenge"}
          </Button>
        </>
      }
    />
  )
}

export default ChallengeDialog
