import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UpdateUser } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateUser = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const update = useCallback(
    (userId: number, updateUserBody: UpdateUser): Promise<UpdateUser> => {
      return makeRequest<UpdateUser>(
        `${config.apiUrl}/internal/v1/users/${userId}`,
        {
          method: "PUT",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...updateUserBody }),
        }
      )
    },
    [authBearer]
  )

  const updateUser = useCallback(
    async (userId: number, updateUserBody: UpdateUser, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await update(userId, updateUserBody)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [update, setLoading, setError]
  )

  return { updateUser, loading, error }
}

export default useUpdateUser
