import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { RewardRedemption } from "../../../types"
import UserRewardRedemptionRow from "./UserRewardRedemptionRow"

interface Props {
  rewardRedemptions: RewardRedemption[]
  paginationData: PaginationData
}

const getRows = (rewardRedemptions: RewardRedemption[]) => {
  return rewardRedemptions.map((item, index) => {
    return <UserRewardRedemptionRow key={index} rewardRedemption={item} />
  })
}

const columns: string[] = ["Brand", "Amount", "Created"]

const UserRewardRedemptionsTable = ({
  rewardRedemptions,
  paginationData,
}: Props) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(rewardRedemptions)}
      paginationOptions={paginationData}
    />
  )
}

export default UserRewardRedemptionsTable
