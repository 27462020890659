import React, { FunctionComponent } from "react"
import { GoogleLogout } from "react-google-login"
import GoogleLogoutPropsExtended from "../../custom-typings/@types/react-google-login/extensions"
import config from "../../config"
import { useAuth } from "../../services/auth-service"

const LogoutButton: FunctionComponent = () => {
  const auth = useAuth()

  const logoutProps = {
    clientId: config.googleClientId,
    buttonText: "Log Out",
    onLogoutSuccess: auth.handleLogout,
    icon: false,
  } as GoogleLogoutPropsExtended.IconedProps

  return <GoogleLogout {...logoutProps} />
}

export default LogoutButton
