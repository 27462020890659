import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AIPrompt, Challenge, CreateChallenge } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGenerateAIPrompts = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<AIPrompt[]>()

  const generate = useCallback(
    (prompts: number, difficulty: string): Promise<AIPrompt[]> => {
      return makeRequest<AIPrompt[]>(
        `${config.apiUrl}/internal/v1/challenges/generate`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompts: Number(prompts), difficulty }),
        }
      )
    },
    [authBearer]
  )

  const generateAIPrompts = useCallback(
    async (prompts: number, difficulty: string, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        const response = await generate(prompts, difficulty)
        setData(response)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [generate, setLoading, setError]
  )

  return { generateAIPrompts, loading, error, data }
}

export default useGenerateAIPrompts
