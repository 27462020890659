import React, { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title1, Title3 } from "../../components/shared/Typography"
import { Box } from "@mui/material"
import useGetUserSubscriptions from "./hooks/useGetUserSubscriptions"
import UserSubscriptionsTable from "./components/UserSubscriptionsTable"

type Params = {
  userId?: string
}

const UserSubscriptionsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const userId = Number(params.userId)

  const {
    getUserSubscriptions,
    subscriptions,
    loading,
    error,
    paginationData,
  } = useGetUserSubscriptions()

  useEffect(() => {
    getUserSubscriptions(userId)
    // eslint-disable-next-line
  }, [])

  if (!subscriptions) {
    return <Title1>Subscriptions not found for user {userId}</Title1>
  }

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
        }}
      ></Box>
      <Title3>User {userId}'s Subscriptions:</Title3>
      {loading ? <LoadingSpinner /> : null}
      <UserSubscriptionsTable
        subscriptions={subscriptions}
        paginationData={paginationData}
      />
    </ScreenContainer>
  )
}

export default UserSubscriptionsScreen
