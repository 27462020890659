import React, { FunctionComponent, useState } from "react"
import GoogleLogin, { GoogleLoginResponse } from "react-google-login"
import { useLocation, useNavigate } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import config from "../../config"
import { useAuth } from "../../services/auth-service"
import enforceError from "../../utils/enforce-error"
import "./LoginScreen.css"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit"

type GoogleLoginError = {
  error: string
  details: string
}

interface RedirectParamsType {
  pathname: string
  search?: string
}

interface SearchParamType {
  name: string
  value: string
}

const createSearchQuery = (searchParams: SearchParamType[]) => {
  let query = ``
  searchParams.forEach((param: SearchParamType, index: number) => {
    query +=
      index === 0
        ? `?${param.name}=${param.value}`
        : `&${param.name}=${param.value}`
  })

  return query
}

const LoginComponent: FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const auth = useAuth()
  const navigate = useNavigate()
  const location: any = useLocation()
  const redirectPath = location?.state?.redirectPath || "/"

  const handleLoginSuccess = async (response: GoogleLoginResponse) => {
    setLoading(true)

    try {
      await auth.handleLogin(response)
      const navigationProps: RedirectParamsType = {
        pathname: redirectPath,
      }
      const redirectSearchParams = location?.state?.searchParams
      if (redirectSearchParams?.length) {
        navigationProps.search = createSearchQuery(redirectSearchParams)
      }
      navigate(navigationProps)
    } catch (error) {
      setError(enforceError(error))
    } finally {
      setLoading(false)
    }
  }

  const handleLoginFailure = (response: GoogleLoginError) => {
    setError(new Error(`${response.error} - ${response.details}`))
    setLoading(false)
  }

  return (
    <div className="login-wrapper">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <MDBContainer fluid>
          <MDBRow className="d-flex justify-content-center align-items-center h-100">
            <MDBCol col="12">
              <MDBCard
                className="bg-white my-5 mx-auto"
                style={{ borderRadius: "1rem", maxWidth: "500px" }}
              >
                <MDBCardBody className="p-5 w-100 d-flex flex-column">
                  <h2 className="fw-bold mb-2 text-center">Login</h2>
                  <p className="text-black-50">
                    Unauthorized access is prohibited
                  </p>

                  {error ? <ErrorComponent error={error} /> : null}

                  <GoogleLogin
                    clientId={config.googleClientId}
                    buttonText="Login"
                    onSuccess={(response) =>
                      handleLoginSuccess(response as GoogleLoginResponse)
                    }
                    onFailure={handleLoginFailure}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={!error}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </div>
  )
}

export default LoginComponent
