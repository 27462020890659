import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Contest } from "../../../types"
import ContestRow from "./ContestRow"

interface ContestsTableProps {
  contests: Contest[]
  paginationData: PaginationData
  onSuccess: any
}

const getRows = (contests: Contest[], onSuccess: any) => {
  return contests.map((contest, index) => {
    return <ContestRow key={index} contest={contest} onSuccess={onSuccess} />
  })
}

const columns: string[] = [
  "ID",
  "Kicker",
  "Title",
  "Subtitle",
  "Active",
  "Created",
  "Action",
]

const ContestsTable = ({
  contests,
  paginationData,
  onSuccess,
}: ContestsTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(contests, onSuccess)}
      paginationOptions={paginationData}
    />
  )
}

export default ContestsTable
