import React, { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  userId: string | number
}

type UserLinkProps = Omit<Props, "to">

const UserLink: FunctionComponent<UserLinkProps> = ({
  userId,
  children,
  ...rest
}) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/users/${userId}`}>
    {children}
  </Link>
)

export default UserLink
