import { useState, useCallback } from "react"

const useToggle = (defaultIsOpen?: boolean) => {
  const [isOn, setIsOn] = useState(defaultIsOpen || false)

  const turnOn = useCallback(() => {
    setIsOn(true)
  }, [])

  const turnOff = useCallback(() => {
    setIsOn(false)
  }, [])

  const toggle = useCallback(() => {
    setIsOn(!isOn)
  }, [isOn])

  return { isOn, turnOn, turnOff, toggle }
}

export default useToggle
