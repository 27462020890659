import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Funds } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetFunds = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Funds>()

  const request = useCallback((): Promise<Funds> => {
    return makeRequest<Funds>(`${config.apiUrl}/internal/v1/contests/funds`, {
      headers: {
        authorization: `Bearer ${authBearer}`,
      },
    })
  }, [authBearer])

  const getFunds = useCallback(async () => {
    setLoading(true)
    setError(null)
    let results
    try {
      results = await request()
      setData(results)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

    return results
  }, [request, setLoading, setError])

  return {
    loading,
    error,
    getFunds,
    funds: data,
  }
}

export default useGetFunds
