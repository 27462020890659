import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Challenge } from "../../../types"
import { formatDate } from "../../../utils/formatters"
import { Button } from "@mui/material"
import ChallengeDialog from "./ChallengeDialog"
import { useNavigate } from "react-router-dom"

type ChallengeRowProps = {
  challenge: Challenge
}

const ChallengeRow: FunctionComponent<ChallengeRowProps> = ({ challenge }) => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BasicTableRow key={challenge.id}>
      <BasicTableCell component="th" scope="row">
        {challenge.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {challenge.prompt}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(challenge.date)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(challenge.active)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button onClick={() => setOpen(true)}>Edit</Button>
        <Button onClick={() => navigate(`/challenges/${challenge.id}`)}>
          View Posts
        </Button>
        {open ? (
          <ChallengeDialog
            isOpen={open}
            close={handleClose}
            challenge={challenge}
          />
        ) : null}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default ChallengeRow
