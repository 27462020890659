import React, { FunctionComponent, useEffect } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { UserReport } from "../../../types"
import { formatDate } from "../../../utils/formatters"
import config from "../../../config"
import UserLink from "../../Users/components/UserLink"
import { Button, Menu, MenuItem } from "@mui/material"
import { toast } from "react-hot-toast"
import useGetUserById from "../hooks/useGetUserById"
import { VerifiedIcon } from "@primer/octicons-react"
import useUpdateReportedUser from "../hooks/useUpdateReportedUser"
import Avatar from "../../../components/shared/Avatar"

type ReportedUserRowProps = {
  report: UserReport
}

const ReportedPostRow: FunctionComponent<ReportedUserRowProps> = ({
  report,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { user, getUserById } = useGetUserById()
  const { updateReportedUser } = useUpdateReportedUser()

  const handlePostActionsClicked = async (action: string) => {
    switch (action) {
      case "deleteUser":
        toast.loading("Creating user deletion request...")
        await updateReportedUser(report.id, action, handleSuccess)
        break
      case "deleteProfilePhoto":
        toast.loading("Removing profile photo...")
        await updateReportedUser(report.id, action, handleSuccess)
        break
      case "deleteReport":
        toast.loading("Removing report...")
        await updateReportedUser(report.id, action, handleSuccess)
        break
      default:
        toast.error("Invalid action clicked")
    }
  }

  const handleSuccess = async () => {
    toast.dismiss()
    toast.success("Success")
    window.location.reload()
  }

  useEffect(() => {
    getUserById(report.type_id)
    // eslint-disable-next-line
  }, [])

  return (
    <BasicTableRow key={report.id}>
      <BasicTableCell component="th" scope="row">
        <Avatar
          src={`${config.assetsBase}/${
            user?.profile_photo ? user?.profile_photo : "/users/null.png"
          }`}
          sx={{
            height: 50,
            width: 50,
            borderRadius: user?.type === "personal" ? "50%" : "10%",
          }}
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={user?.id!}>{user?.id}</UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {user?.name}{" "}
        {user?.verified ? (
          <>
            <VerifiedIcon />
          </>
        ) : null}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        @{user?.username}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={report.reported_by}>{report.reported_by}</UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(report.created_at)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handlePostActionsClicked("deleteUser")}>
            Delete User (creates request)
          </MenuItem>
          <MenuItem
            onClick={() => handlePostActionsClicked("deleteProfilePhoto")}
          >
            Delete Profile Photo
          </MenuItem>
          <MenuItem onClick={() => handlePostActionsClicked("deleteReport")}>
            Delete Report (user remains)
          </MenuItem>
        </Menu>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default ReportedPostRow
