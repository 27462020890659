import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { UserNote } from "../../../types"
import UserNoteRow from "./UserNoteRow"

interface UserNotesTableProps {
  userNotes: UserNote[]
  paginationData: PaginationData
}

const getRows = (userNotes: UserNote[]) => {
  return userNotes.map((userNoteItem, index) => {
    return <UserNoteRow key={index} userNote={userNoteItem} />
  })
}

const columns: string[] = ["Note", "Author", "Added"]

const UserNotesTable = ({ userNotes, paginationData }: UserNotesTableProps) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(userNotes)}
      paginationOptions={paginationData}
    />
  )
}

export default UserNotesTable
