import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { DeadletterEvent } from "../../../types"
import DeadletterEventRow from "./DeadletterEventRow"

interface Props {
  events: DeadletterEvent[]
  paginationData: PaginationData
}

const getRows = (events: DeadletterEvent[]) => {
  return events.map((event, index) => {
    return <DeadletterEventRow key={index} event={event} />
  })
}

const columns: string[] = ["ID", "Type", "Queue", "Body", "Actions", "Created"]

const DeadletterEventsTable = ({ events, paginationData }: Props) => {
  return (
    <BasicTable
      columns={columns}
      rows={getRows(events)}
      paginationOptions={paginationData}
    />
  )
}

export default DeadletterEventsTable
