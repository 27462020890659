import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
} from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { User } from "../../../types"
import useUpdateUser from "../hooks/useUpdateUser"

interface Props {
  isOpen: boolean
  close: () => void
  user: User
}

const UpdateUserDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  user,
}) => {
  const [verified, setVerified] = useState<boolean>(false)
  const [shadowBanned, setShadowBanned] = useState<boolean>(false)
  const [isReserved, setIsReserved] = useState<boolean>(false)
  const [showPosts, setShowPosts] = useState<boolean>(true)

  const { updateUser, loading, error } = useUpdateUser()

  const validationSchema = Yup.object().shape({
    name: Yup.string().optional(),
    username: Yup.string().optional(),
    bio: Yup.string().optional(),
    verified: Yup.boolean().optional(),
    type: Yup.string().optional(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      bio: "",
      type: "personal",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      await updateUser(
        user.id,
        {
          name: values.name,
          username: values.username,
          bio: values.bio,
          verified: verified,
          is_shadow_banned: shadowBanned,
          type: values.type,
          is_reserved: isReserved,
          show_posts: showPosts,
        },
        handleUpdateSuccess
      )
    },
  })

  const handleUpdateSuccess: any = async () => {
    toast.success("Successfully updated user")
    close()
  }

  useEffect(() => {
    formik.setValues({
      name: user.name,
      username: user.username,
      bio: user.bio,
      type: user.type,
    })
    setVerified(user.verified)
    setShadowBanned(user.is_shadow_banned)
    setIsReserved(user.is_reserved)
    setShowPosts(user.show_posts)

    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={`Editing ${user.name}`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={!!formik.errors.name}
          />
          <TextField
            margin="dense"
            id="username"
            name="username"
            label="Username"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.username}
            error={!!formik.errors.username}
          />
          <TextField
            margin="dense"
            id="bio"
            name="bio"
            label="Bio"
            fullWidth
            multiline
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.bio}
            error={!!formik.errors.bio}
          />
          <RadioGroup
            aria-label="Account Type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            row
          >
            <FormControlLabel
              value="personal"
              control={<Radio />}
              label="Personal"
            />
            <FormControlLabel
              value="business"
              control={<Radio />}
              label="Business"
            />
            <FormControlLabel
              value="government"
              control={<Radio />}
              label="Government"
            />
          </RadioGroup>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Username</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.affiliations?.map((affiliation) => (
                  <TableRow key={affiliation.id}>
                    <TableCell>{affiliation.id}</TableCell>
                    <TableCell>{affiliation.name}</TableCell>
                    <TableCell>{affiliation.username}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FormControlLabel
            control={
              <Switch
                checked={showPosts}
                onChange={(e) => setShowPosts(e.target.checked)}
              />
            }
            label="Show Posts"
          />
          <FormControlLabel
            control={
              <Switch
                checked={shadowBanned}
                onChange={(e) => setShadowBanned(e.target.checked)}
              />
            }
            label="Shadowbanned"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isReserved}
                onChange={(e) => setIsReserved(e.target.checked)}
              />
            }
            label="Reserved Handle"
          />
          <FormControlLabel
            control={
              <Switch
                checked={verified}
                onChange={(e) => setVerified(e.target.checked)}
              />
            }
            label="Verified user"
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Save Changes
          </Button>
        </>
      }
    />
  )
}

export default UpdateUserDialog
