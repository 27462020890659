import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Contest } from "../../../types"
import { Button } from "@mui/material"
import ContestDialog from "./ContestDialog"
import ContestWinnersDialog from "./ContestWinnersDialog"

type ContestRowProps = {
  contest: Contest
  onSuccess: any
}

const ContestRow: FunctionComponent<ContestRowProps> = ({
  contest,
  onSuccess,
}) => {
  const [open, setOpen] = React.useState(false)
  const [winnersOpen, setWinnersOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    onSuccess()
  }

  const handleClickWinnersOpen = () => {
    setWinnersOpen(true)
  }

  const handleWinnesrClose = () => {
    setWinnersOpen(false)
    onSuccess()
  }

  return (
    <BasicTableRow key={contest.id}>
      <BasicTableCell component="th" scope="row">
        {contest.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {contest.kicker}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {contest.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {contest.subtitle}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(contest.active)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {contest.created_at}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Edit
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickWinnersOpen}
          sx={{ ml: 1 }}
        >
          Winners
        </Button>
      </BasicTableCell>
      {open ? (
        <ContestDialog isOpen={open} close={handleClose} contest={contest} />
      ) : null}
      {winnersOpen ? (
        <ContestWinnersDialog
          isOpen={winnersOpen}
          close={handleWinnesrClose}
          contest={contest}
        />
      ) : null}
    </BasicTableRow>
  )
}

export default ContestRow
