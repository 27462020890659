import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { UserNote } from "../../../types"
import { formatDate } from "../../../utils/formatters"

type UserNoteRowProps = {
  userNote: UserNote
}

const UserNoteRow: FunctionComponent<UserNoteRowProps> = ({ userNote }) => {
  return (
    <BasicTableRow key={userNote.id}>
      <BasicTableCell component="th" scope="row">
        {userNote.note}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {userNote.employee.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(userNote.created_at)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserNoteRow
