import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { LeaderboardUser } from "../../../types"
import config from "../../../config"
import UserLink from "../../Users/components/UserLink"
import { VerifiedIcon } from "@primer/octicons-react"
import Avatar from "../../../components/shared/Avatar"

type LeaderboardUserRowProps = {
  user: LeaderboardUser
}

const LeaderboardUserRow: FunctionComponent<LeaderboardUserRowProps> = ({
  user,
}) => {
  return (
    <BasicTableRow key={user.rank}>
      <BasicTableCell component="th" scope="row">
        {user.rank}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Avatar
          src={`${config.assetsBase}/${
            user.user.profile_photo
              ? user.user.profile_photo
              : "/users/null.png"
          }`}
          sx={{
            height: 50,
            width: 50,
            borderRadius: user.user.type === "personal" ? "50%" : "10%",
          }}
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <UserLink userId={user.user_id}>
          {user.user.name}{" "}
          {user.user.verified ? (
            <>
              <VerifiedIcon />
            </>
          ) : null}
        </UserLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {user.user.username}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {user.total_value} {user.total_value === 1 ? "Point" : "Points"}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default LeaderboardUserRow
