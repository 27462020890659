import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Subscription } from "../../../types"
import { convertCentsToDollar, formatDate } from "../../../utils/formatters"

type Props = {
  subscription: Subscription
}

const UserSubscriptionRow: FunctionComponent<Props> = ({ subscription }) => {
  return (
    <BasicTableRow key={subscription.id}>
      <BasicTableCell component="th" scope="row">
        {subscription.store}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subscription.sku}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subscription.price}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subscription.status}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserSubscriptionRow
