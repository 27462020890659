import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Reward } from "../../../types"
import { convertCentsToDollar, formatDate } from "../../../utils/formatters"

type Props = {
  reward: Reward
}

const UserRewardRow: FunctionComponent<Props> = ({ reward }) => {
  return (
    <BasicTableRow key={reward.id}>
      <BasicTableCell component="th" scope="row">
        ${convertCentsToDollar(reward.amount)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {reward.status}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {reward.source}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(reward.created_at)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserRewardRow
