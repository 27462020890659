import React, { FunctionComponent } from "react"

type ErrorComponentProps = {
  error: Error
}

const ErrorComponent: FunctionComponent<ErrorComponentProps> = ({ error }) => {
  return (
    <div>
      <span>{`Error: ${error.message}`}</span>
    </div>
  )
}

export default ErrorComponent
