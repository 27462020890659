import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

const Card = styled("li")`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.card};
`

export const CardRow = styled(Box)``
CardRow.defaultProps = {
  sx: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}

export const CardColumn = styled(Box)``
CardColumn.defaultProps = {
  sx: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}

export default Card