import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { useParams } from "react-router-dom"
import { Card, CardContent, Grid, Typography } from "@mui/material"
import useGetUserRewards from "./hooks/useGetUserRewards"
import useGetUserRewardRedemptions from "./hooks/useGetUserRewardRedemptions"
import UserRewardsTable from "./components/UserRewardsTable"
import UserRewardRedemptionsTable from "./components/UserRewardRedemptionsTable"
import useGetUserBalance from "./hooks/useGetUserBalance"
import { convertCentsToDollar } from "../../utils/formatters"

type Params = {
  userId?: string
}

const UserRewardsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const userId = Number(params.userId)

  const { rewards, getUserRewards, paginationData, error, loading } =
    useGetUserRewards()

  const {
    rewardRedemptions,
    getUserRewardRedemptions,
    paginationData: redemptionsPaginationData,
    error: redemptionsError,
    loading: redemptionsLoading,
  } = useGetUserRewardRedemptions()

  const {
    balance,
    getUserBalance,
    loading: balanceLoading,
    error: balanceError,
  } = useGetUserBalance()

  useEffect(() => {
    getUserRewards(userId)
    getUserRewardRedemptions(userId)
    getUserBalance(userId)
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title={`User ${userId} Rewards`}>
      {loading || redemptionsLoading || balanceLoading ? (
        <LoadingSpinner />
      ) : null}
      <Card sx={{ minWidth: "100%" }}>
        <Typography variant="body1" sx={{ margin: 1 }}>
          {balanceError ? <ErrorComponent error={balanceError} /> : null}
          <b>Current Balance:</b> ${convertCentsToDollar(balance?.amount || 0)}
        </Typography>
        <Typography variant="body1" sx={{ margin: 1 }}>
          <b>Expired Balance:</b> $
          {convertCentsToDollar(balance?.expired_amount || 0)}
        </Typography>
      </Card>
      <Card sx={{ minWidth: "100%", mt: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="h6">Rewards</Typography>
                </Grid>
                {error ? <ErrorComponent error={error} /> : null}
              </Grid>
              <UserRewardsTable
                rewards={rewards}
                paginationData={paginationData}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Reward Redemptions</Typography>
              {redemptionsError ? (
                <ErrorComponent error={redemptionsError} />
              ) : null}
              <UserRewardRedemptionsTable
                rewardRedemptions={rewardRedemptions}
                paginationData={redemptionsPaginationData}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ScreenContainer>
  )
}

export default UserRewardsScreen
