import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import ReportedUsersTable from "./components/ReportedUsersTable"
import useGetReportedUsers from "./hooks/useGetReportedUsers"

const ReportedUsersScreen: FunctionComponent = () => {
  const { reports, paginationData, getReportedUsers, error, loading } =
    useGetReportedUsers()

  useEffect(() => {
    getReportedUsers()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Reported Users">
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ReportedUsersTable reports={reports} paginationData={paginationData} />
      )}
    </ScreenContainer>
  )
}

export default ReportedUsersScreen
